import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";

export default function Sidebar(props) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Drawer
        anchor={"left"}
        open={props.drawer}
        onClose={props.toggleDrawer(false)}
        PaperProps={{
          sx: {
            background: ({ palette }) =>
              palette.background.default + "!important",
          },
        }}
        elevation={0}
      >
        <Box
          role="presentation"
          width={220}
          onClick={props.toggleDrawer(false)}
          onKeyDown={props.toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={() => navigate("/")}>
              <ListItemText primary={"Почетна"} />
            </ListItem>

            <ListItem button onClick={() => navigate("/contact")}>
              <ListItemText primary={"Контакт"} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

/*
         <ListItem button onClick={() => navigate("/")}>
              <ListItemText primary={"Резервирај Термин"} />
            </ListItem>
            <ListItem button onClick={() => navigate("/")}>
              <ListItemText primary={"Побарај Понуда"} />
            </ListItem>
                  <ListItem button onClick={() => navigate("/podarok")}>
              <ListItemText primary={"Земи Подарок"} />
            </ListItem>

*/
