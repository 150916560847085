import React from "react";
import { createRoot } from "react-dom/client";
//import ReactDOM from "react-dom";

import "./global";

import Portal from "./Portal/Portal";

//import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("uni"));
root.render(<Portal />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);

/**
 * react 18 changes:
 * 
 * 
ReactDOM.render(
  <React.StrictMode>
    <Portal />
  </React.StrictMode>,
  document.getElementById("uni")
);
 */
