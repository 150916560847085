import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";

export default function AboutUs() {
  return (
    <React.Fragment>
      <Box
        pt={{ xs: 6, sm: 7 }}
        pb={{ xs: 5, sm: 6 }}
        mt={{
          xs: 7,
          sm: 8,
        }}
        sx={{
          width: "100%",
          background: (theme) => theme.palette.page.name.background,
          pointerEvents: "none",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        <Container maxWidth={"xl"}>
          <Typography
            variant={"h5"}
            component={"h1"}
            my={2}
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightBold,
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            За Нас
          </Typography>
        </Container>
      </Box>

      <Box
        mt={{
          xs: 7,
          sm: 8,
        }}
      >
        <Container maxWidth={"md"}>
          <Paper elevation={0}>
            <Typography variant="body1" gutterBottom paragraph px={2} pt={3}>
              Carefree on winter roads is invaluable. Feeling of grip regardless
              of cold, snow and ice, as well as short braking disturbances on
              wet and dry roads are the main reasons why drivers opt for the
              premium tire segment. Superior driving characteristics are also
              regularly proven by a number of independent tests.
            </Typography>
            <Typography variant="body1" gutterBottom paragraph px={2}>
              We are extremely proud of
              <Box component="span" fontWeight="fontWeightMedium">
                this year's ranking on the podium in one of the most respected
                publications for testing in the ADAC industry, and their
                excellence has also been confirmed by AMZS
              </Box>
              .
            </Typography>
            <Typography variant="body1" gutterBottom paragraph px={2}>
              It is an achievement that for Goodyear means
              <Box component="span" fontWeight="fontWeightMedium">
                recognition for the technology of the latest tires on offer
              </Box>
              . Innovations such as Winter Grip technology keep Goodyear tires
              (UltraGrip 9+ and Performance +) at the very top of the segment,
              in which the UltraGrip family has been winning for decades.
            </Typography>
            <Typography variant="body1" paragraph px={2} pb={3}>
              To make the decision even easier this winter, with the
              <Box component="span" fontWeight="fontWeightMedium">
                simultaneous purchase of 4 any winter or all-season Goodyear
                tires in the size of 16" or more at an authorized point of sale
                between 1 October and 20 November 2021, we will give you a warm
                and soft blanket from the renowned Vinga brand. of Sweden
              </Box>
              .
            </Typography>
          </Paper>
        </Container>
      </Box>

      <Container maxWidth={"xl"}></Container>
    </React.Fragment>
  );
}
