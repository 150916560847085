import React, { useState, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemText,
  Alert,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faCheckDouble,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";

import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import { Map } from "pigeon-maps";
import { maptiler } from "pigeon-maps/providers";

const maptilerProvider = maptiler("NGmLCKhE4UlFsgFQTO5p", "topo");

function checkLocalPrefix(number, reference) {
  return reference
    .filter((item) => number.startsWith(item.prefix))
    .reduce(
      (previous, current) =>
        previous.prefix > current.prefix ? previous : current,
      {}
    );
}

function isEmail(email) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

const initialData = {
  value: "",
  required: true,
  disabled: false,
  valid: false,
  error: false,
  message: "",
};

export default function ContactUs() {
  const reCaptchaRef = useRef();

  const [formName, setFormName] = useState(initialData);
  const [formPrefix, setFormPrefix] = useState(initialData);
  const [formPhone, setFormPhone] = useState(initialData);
  const [formEmail, setFormEmail] = useState(initialData);
  const [formSubject, setFormSubject] = useState(initialData);
  const [formMessage, setFormMessage] = useState(initialData);
  const [formAnswer, setFormAnswer] = useState(initialData);
  const [form, setForm] = useState({
    buttonDisabled: false,
    loading: false,
    messageText: null,
    messageSeverity: "info",
    hide: false,
    title: "Пиши ни...",
  });

  const handleName = (e) => {
    const { value } = e.target;

    /*
    var inputValue = value.replace(
      /^[A-Za-z.!@?#"$%&:;() *\+,\/;\-=[\\\]\^_{|}<>\u0400-\u04FF]*$/g,
      ""
    );
    */

    var inputValue = value;

    e.target.value = inputValue;

    var isValid = false;
    var isError = false;
    var threshold = { minimum: 5, maximum: 50 };
    var message = "";

    if (inputValue.length < threshold.minimum) {
      isValid = false;
      isError = true;

      if (inputValue.length === 0) {
        message = "Внесете име!";
      } else {
        var sumVal = threshold.minimum - inputValue.length;
        if (sumVal === 1) {
          message =
            "Внесете уште " +
            (threshold.minimum - inputValue.length) +
            " карактер...";
        } else {
          message =
            "Внесете уште минимум " +
            (threshold.minimum - inputValue.length) +
            " карактери...";
        }
      }
    } else {
      if (inputValue.length > threshold.maximum) {
        isValid = false;
        isError = true;

        message =
          "Внесовте повеќе од дозволените " + threshold.maximum + " карактери!";
      } else {
        var countSpaces = value.split(" ").length - 1;

        if (countSpaces > 0 && countSpaces <= 3) {
          isValid = true;
          isError = false;
          message = "";
        } else {
          isValid = false;
          isError = true;
          message = "Внесете целосно име и презиме!";
        }
      }
    }

    // Update the state
    setFormName({
      ...formName,
      valid: isValid,
      error: isError,
      message,
      value: inputValue,
    });
  };

  const handlePrefix = (e) => {
    const { value } = e.target;

    var isValid = false;
    var isError = false;
    var isRequired = true;
    var isPhoneDisabled = false;
    var isPhoneRequired = true;
    var isPhoneError = false;
    var message = "";
    var phoneMessage = "Не е потребно да внесете телефон.";

    // Disable the phone field when supported country is not selected
    if (value === "else") {
      isPhoneDisabled = true;
      isPhoneRequired = false;
      isRequired = false;
      isPhoneError = false;
    } else {
      isPhoneDisabled = false;
      isPhoneRequired = true;
      phoneMessage = "";
      isRequired = true;
      isPhoneError = false;
    }

    if (value.length === 0) {
      isValid = false;
      isError = true;
    } else {
      isValid = true;
      isError = false;
    }

    // Update state
    setFormPrefix({
      ...formPrefix,
      value,
      valid: isValid,
      error: isError,
      required: isRequired,
      message,
    });
    setFormPhone({
      ...formPhone,
      message: phoneMessage,
      disabled: isPhoneDisabled,
      required: isPhoneRequired,
      error: isPhoneError,
    });
  };

  const handlePhone = (e) => {
    const { value } = e.target;

    // Normalize the number
    var number = String(Number(value.replace(/[^\d]/g, "")));
    var numberDisplay = number.startsWith("0") ? number : "0" + number;

    var prefix = "";
    var isInvalid = false;
    var isError = false;
    var isDisabled = false;
    var isRequired = true;
    var message = "";
    var isLocalPrefixValid = {};
    var isLocalPrefixValidLength = 0;
    var messagePrefix = "";
    var isPrefixError = false;

    if (number.length > 0) {
      // Make sure there is a value selected
      if (formPrefix.value.length > 0) {
        // Check wheter country is supported
        if (global.config.country[formPrefix.value]) {
          // Set the prefix and format
          prefix = global.config.country[formPrefix.value].prefix;

          // If there is a country prefix, remove it
          if (number.startsWith(prefix)) {
            number = number.slice(prefix.length);
            numberDisplay = number.startsWith("0") ? number : "0" + number;
          }

          // Country is supported
          isDisabled = false;

          // Validate the local prefix
          isLocalPrefixValid = checkLocalPrefix(
            number,
            global.config.country[formPrefix.value].localPrefix
          );
          isLocalPrefixValidLength = Object.entries(isLocalPrefixValid).length;

          // Evaluates if the number meets the minimal length
          // It does not
          if (
            number.length < global.config.country[formPrefix.value].lengthMin
          ) {
            isInvalid = true;

            // When local prefix is found
            // And it's not accepted
            // And the number currently entered exceeds the length of the prefix
            // Return an error with a wrong number message
            if (
              isLocalPrefixValidLength > 0 &&
              !isLocalPrefixValid.accepted &&
              number.length > isLocalPrefixValid.prefix.length
            ) {
              message = "Погрешен број!";
              isError = true;
            }
            // When local prefix is found
            // And it's not accepted
            // The local prefix is smaller or equal to the numbeter entered
            // Show the helper format message
            else if (
              isLocalPrefixValidLength > 0 &&
              !isLocalPrefixValid.accepted &&
              isLocalPrefixValid.prefix.length <= number.length
            ) {
              message = "Внесете точен број " + isLocalPrefixValid.helper;
              isError = false;
            }
            // Local prefix is accepted is correct
            // But the number length is not satisfactory
            else if (
              isLocalPrefixValidLength > 0 &&
              isLocalPrefixValid.accepted
            ) {
              message = "Внесете точен број " + isLocalPrefixValid.helper;
              isError = false;
            }
            // Fallback
            // With generic helper format
            else {
              message = "Внесете точен број 07X XXX XXX";
              isError = true;
            }
          }
          // Number is assumed to be correct
          else if (
            isLocalPrefixValidLength > 0 &&
            isLocalPrefixValid.accepted &&
            number.length >=
              global.config.country[formPrefix.value].lengthMin &&
            number.length <= global.config.country[formPrefix.value].lengthMax
          ) {
            isInvalid = false;
            isError = false;
            message = "";
          }
          // Fallback, wrong number
          else {
            isInvalid = true;
            isError = true;
            message = "Погрешен број!";
          }
        }
        // Country not supported
        // Disable the input field
        else {
          isInvalid = false;
          isDisabled = true;
          message = "Не е потребно да внесете телефон.";
          e.target.value = "";
          isRequired = false;
        }
      }
      // Prefix not selected
      // Prompt the user to select a prefix
      else {
        isInvalid = true;
        isDisabled = true;
        message = "";
        messagePrefix = "Изберете префикс!";
        e.target.value = "";
        isPrefixError = true;
        setFormPrefix({ message: messagePrefix, error: isPrefixError });
        isRequired = true;
      }

      // Update state
      setFormPhone({
        ...formPhone,
        value: number,
        message,
        disabled: isDisabled,
        valid: !isInvalid,
        error: isError,
        required: isRequired,
      });
      e.target.value = numberDisplay;
    }
  };

  const handleEmail = (e) => {
    const { value } = e.target;

    var isValid = false;
    var isError = false;
    var message = "";

    if (value.length < 5) {
      isValid = false;
      isError = true;
      message = "";
    } else {
      if (isEmail(value)) {
        isValid = true;
        isError = false;
        message = "";
      } else {
        isValid = false;
        isError = true;
        message = "Внесете валидна e-mail адреса!";
      }
    }

    // Update the state
    setFormEmail({
      ...formEmail,
      valid: isValid,
      error: isError,
      value,
      message,
    });
  };

  const handleSubject = (e) => {
    const { value } = e.target;

    var isValid = false;
    var isError = false;
    var message = "";

    if (value.length === 0) {
      isValid = false;
      isError = true;
      message = "Изберете предмет!";
    } else {
      isValid = true;
      isError = false;
      message = "";
    }

    // Update state
    setFormSubject({
      ...formSubject,
      value,
      valid: isValid,
      error: isError,
      message,
    });
  };

  const handleMessage = (e) => {
    const { value } = e.target;

    var isValid = false;
    var isError = false;
    var threshold = { minimum: 20, maximum: 1200 };
    var message = "";

    if (value.length < threshold.minimum) {
      isValid = false;
      isError = true;

      if (value.length === 0) {
        message = "Внесете порака!";
      } else {
        message =
          "Внесете уште минимум " +
          (threshold.minimum - value.length) +
          " карактери...";
      }
    } else {
      if (value.length > threshold.maximum) {
        isValid = false;
        isError = true;

        message =
          "Внесовте повеќе од дозволените " + threshold.maximum + " карактери!";
      } else {
        isValid = true;
        isError = false;
        message = "";
      }
    }

    // Update the state
    setFormMessage({
      ...formMessage,
      valid: isValid,
      error: isError,
      message,
      value,
    });
  };

  const handleAnswer = (e) => {
    const { value } = e.target;

    var isValid = false;
    var isError = false;
    var message = "";

    if (value.length === 0) {
      if (formEmail.valid) {
        isValid = true;
        isError = false;
        message = "Одговорот ќе го добиете на " + formEmail.value;
        e.target.value = "email";
      }
      isValid = false;
      isError = true;
      message = "Изберете како да ви одговориме!";
    } else {
      isValid = true;
      isError = false;
      if (value === "email" && formEmail.valid) {
        message = "Одговорот ќе го добиете на " + formEmail.value;
      }
      message = "";
    }

    // Update state
    setFormAnswer({
      ...formAnswer,
      value,
      valid: isValid,
      error: isError,
      message,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Disable the button and enable loading
    // Clear any errors and the message
    setForm({
      ...form,
      buttonDisabled: true,
      loading: true,
      messageText: null,
      messageSeverity: "info",
    });

    // Reset ReCaptcha
    reCaptchaRef.current.reset();

    var validSum = 0;

    var isErrorName = false;
    var isErrorPrefix = false;
    var isErrorPhone = false;
    var isErrorEmail = false;
    var isErrorSubject = false;
    var isErrorMessage = false;
    var isErrorAnswer = false;

    if (formName.required && !formName.valid) {
      validSum++;
      isErrorName = true;
    }
    if (formPrefix.required && !formPrefix.valid) {
      validSum++;
      isErrorPrefix = true;
    }
    if (formPhone.required && !formPhone.valid) {
      validSum++;
      isErrorPhone = true;
    }
    if (formEmail.required && !formEmail.valid) {
      validSum++;
      isErrorEmail = true;
    }
    if (formSubject.required && !formSubject.valid) {
      validSum++;
      isErrorSubject = true;
    }
    if (formMessage.required && !formMessage.valid) {
      validSum++;
      isErrorMessage = true;
    }
    if (formAnswer.required && !formAnswer.valid) {
      validSum++;
      isErrorAnswer = true;
    }

    console.log({ validSum });
    //console.log({ formName }, formName.valid);
    //console.log({ formPrefix }, formPrefix.valid);
    //console.log({ formPhone }, formPhone.valid);
    //console.log({ formEmail }, formEmail.valid);
    //console.log({ formSubject }, formSubject.valid);
    //console.log({ formMessage }, formMessage.valid);
    //console.log({ formAnswer }, formAnswer.valid);

    setFormName({
      ...formName,
      error: isErrorName,
    });
    setFormPrefix({
      ...formPrefix,
      error: isErrorPrefix,
    });
    setFormPhone({
      ...formPhone,
      error: isErrorPhone,
    });
    setFormEmail({
      ...formEmail,
      error: isErrorEmail,
    });
    setFormSubject({
      ...formSubject,
      error: isErrorSubject,
    });
    setFormMessage({
      ...formMessage,
      error: isErrorMessage,
    });
    setFormAnswer({
      ...formAnswer,
      error: isErrorAnswer,
    });

    if (validSum === 0) {
      console.log("Ready to submit!!!");

      // Turn on the loader
      // Disable the button
      // And clear any messages
      setForm({
        ...form,
        buttonDisabled: true,
        loading: true,
        messageText: null,
      });

      console.log("formName", formName.value);
      console.log("formPrefix", formPrefix.value);
      console.log("formPhone", formPhone.value);
      console.log("formEmail", formEmail.value);
      console.log("formSubject", formSubject.value);
      console.log("formMessage", formMessage.value);
      console.log("formAnswer", formAnswer.value);

      const token = await reCaptchaRef.current.executeAsync();
      console.log({ token });

      var prefix = "else";
      if (
        global.config.country[formPrefix.value].prefix &&
        global.config.country[formPrefix.value].prefix.length > 0
      ) {
        prefix = global.config.country[formPrefix.value].prefix;
      }

      axios
        .post(
          "https://contact.uni.mk/vulco/default",
          {
            name: formName.value,
            prefix,
            phone: formPhone.value,
            email: formEmail.value,
            subject: formSubject.value,
            message: formMessage.value.replace(/(?:\r\n|\r|\n)/g, "<br>"),
            answer: formAnswer.value,
          },
          {
            headers: {
              "uni-captcha": token,
            },
          }
        )
        .then((res) => {
          console.log("Form Submission Response:", res);
          setForm({
            ...form,
            buttonDisabled: true,
            loading: true,
            messageText: "Успешно испратено!",
            messageSeverity: "success",
            hide: true,
            title: "Ти благодариме!",
          });
        })
        .catch((err) => {
          console.log("Form Submission Error:", err);
          if (err.response && err.response.status) {
            if (err.response.status === 422) {
              // cant understand data
              setForm({
                ...form,
                buttonDisabled: false,
                loading: false,
                messageText: "Точно пополни ги сите полиња!",
                messageSeverity: "error",
              });
            } else if (err.response.status === 403) {
              // cannot authenticate user
              setForm({
                ...form,
                buttonDisabled: false,
                loading: false,
                messageText:
                  "Не можеме да го автентицираме вашиот прелистувач! Обиди се повторно!",
                messageSeverity: "error",
              });
            } else {
              setForm({
                ...form,
                buttonDisabled: false,
                loading: false,
                messageText: "Настана непозната грешка... Обиди се повторно!",
                messageSeverity: "error",
              });
            }
          } else {
            setForm({
              ...form,
              buttonDisabled: false,
              loading: false,
              messageText: "Настана непозната грешка... Обиди се повторно!",
              messageSeverity: "error",
            });
          }
        });
    } else {
      setForm({
        ...form,
        buttonDisabled: false,
        loading: false,
        messageText: `Пополни ги сите полиња правилно!`,
        messageSeverity: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          background: ({ palette }) => palette.page.name.background,
          pointerEvents: "none",
          textAlign: "center",
          textTransform: "uppercase",
          height: {
            xs: "28rem",
            sm: "29rem",
            md: "32rem",
            lg: "38rem",
          },
        }}
      >
        <Map
          provider={maptilerProvider}
          defaultCenter={[41.345351496587885, 21.56500234110443]}
          defaultZoom={18}
          attribution={false}
        />
        <Box
          sx={{
            position: "absolute",
            top: {
              xs: "13.8rem",
              sm: "14.8rem",
              md: "16.3rem",
              lg: "19.25rem",
            },
            left: "50%",
            ml: "-1.7rem",
            fontSize: "3rem",
            color: ({ palette }) => palette.primary.main,
          }}
        >
          <FontAwesomeIcon icon={faMapMarker} fixedWidth />
        </Box>
      </Box>

      <Box
        marginTop={{
          xs: 7,
          sm: 8,
        }}
      >
        <Container maxWidth={"lg"}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={{ xs: 0, md: 0, lg: 4 }}
          >
            <Box
              // Details
              mx={{ xs: 3, md: 2 }}
              //mt={{ xs: 1, md: 1 }}
              mb={{ xs: 0, md: 3 }}
            >
              <List sx={{ pt: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Vulco Прилеп"
                    sx={{ lineHeight: "1" }}
                    primaryTypographyProps={{ variant: "h5" }}
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Работно Време"
                    primaryTypographyProps={{ variant: "button" }}
                    secondary={
                      <Typography component="span" variant="body1">
                        Понеделник—Сабота
                        <br />
                        од 8:00 до 19:00
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Адреса"
                    primaryTypographyProps={{ variant: "button" }}
                    component="div"
                    secondaryTypographyProps={{ component: "div" }}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body1"
                          color="text.primary"
                        >
                          Александар Македонски 428
                          <br />
                          7500, Прилеп (МК)
                        </Typography>
                        <Box fullWidth mt={1}>
                          <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            href="https://g.page/vulco-pp"
                            target="_blank"
                          >
                            Отвори на Google Maps
                          </Button>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Бесплатен Телефон"
                    primaryTypographyProps={{ variant: "button" }}
                    secondary={
                      <Typography component="span" variant="body1">
                        0800 VULCO (88526)
                      </Typography>
                    }
                  />
                </ListItem>

                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Телефон"
                    primaryTypographyProps={{ variant: "button" }}
                    secondary={
                      <Typography component="span" variant="body1">
                        +389 (0)48 410 164
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Box>

            <Paper
              // Form
              elevation={0}
              sx={{
                pr: { xs: 5, md: 3 },
                pl: { xs: 5, md: 3 },
                pt: 2,
                pb: 2.5,
                width: { xs: "100%", md: "65%", lg: "55%" },
              }}
            >
              <Typography
                component="h3"
                variant="h5"
                sx={{ mb: 4, lineHeight: "1" }}
              >
                {form.title}
              </Typography>

              {form.messageText ? (
                <Alert
                  variant="filled"
                  severity={form.messageSeverity}
                  iconMapping={{
                    error: (
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        fixedWidth
                        fontSize="inherit"
                      />
                    ),
                    success: (
                      <FontAwesomeIcon
                        icon={faCheckDouble}
                        fixedWidth
                        fontSize="inherit"
                      />
                    ),
                    warning: (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        fixedWidth
                        fontSize="inherit"
                      />
                    ),
                    info: (
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        fixedWidth
                        fontSize="inherit"
                      />
                    ),
                  }}
                  sx={{
                    mt: -1.5,
                    mb: form.messageSeverity === "success" ? 0 : 2,
                  }}
                >
                  {form.messageText}
                </Alert>
              ) : null}

              {!form.hide && (
                <form onSubmit={handleFormSubmit}>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    //sx={{ width: "100%" }}
                  >
                    <TextField
                      id="name"
                      label="Име Презиме"
                      variant="outlined"
                      disabled={formName.disabled}
                      error={formName.error}
                      helperText={formName.message}
                      onChange={handleName}
                      sx={{ minWidth: { xs: "90%", sm: "55%", md: 250 } }}
                    />

                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="flex-start"
                      alignItems={{ xs: "flex-start", md: "stretch" }}
                      spacing={{ xs: 2, md: 1 }}
                      sx={{ width: "100%" }}
                    >
                      <FormControl
                        sx={{ minWidth: { xs: "60%", sm: 180, md: 130 } }}
                      >
                        <InputLabel id="prefix-label">Префикс</InputLabel>
                        <Select
                          //autoWidth
                          labelId="prefix-label"
                          id="prefix"
                          disabled={formPrefix.disabled}
                          value={formPrefix.value ?? ""}
                          error={formPrefix.error}
                          defaultValue={""}
                          label="Префикс"
                          onChange={handlePrefix}
                          renderValue={(selected) => {
                            if (selected.length === 0) return <em>Префикс</em>;

                            if (global.config.country[selected])
                              return (
                                "+" + global.config.country[selected].prefix
                              );
                            else if (selected === "else") return "Друг";
                            else return selected;
                          }}
                        >
                          <MenuItem value={""} disabled>
                            <em>Избери префикс...</em>
                          </MenuItem>
                          <MenuItem value={"MK"}>
                            Македонија +{global.config.country["MK"].prefix}
                          </MenuItem>
                          <MenuItem value={"else"}>Друг</MenuItem>
                        </Select>
                        <FormHelperText sx={{ mt: 0 }}>
                          {formPrefix.message}
                        </FormHelperText>
                      </FormControl>

                      <FormControl
                        sx={{ minWidth: { xs: "80%", sm: "60%", md: 250 } }}
                      >
                        <TextField
                          id="phone"
                          type="tel"
                          label="Телефон"
                          variant="outlined"
                          disabled={formPhone.disabled}
                          error={formPhone.error}
                          helperText={formPhone.message}
                          onKeyUp={handlePhone}
                        />
                      </FormControl>
                    </Stack>

                    <TextField
                      sx={{ minWidth: { xs: "100%", sm: "80%", md: 300 } }}
                      id="email"
                      type="email"
                      label="E-mail"
                      variant="outlined"
                      disabled={formEmail.disabled}
                      error={formEmail.error}
                      helperText={formEmail.message}
                      onKeyUp={handleEmail}
                    />

                    <FormControl
                      sx={{ minWidth: { xs: "65%", sm: 210, md: 150 } }}
                    >
                      <InputLabel id="subject-label">Предмет</InputLabel>
                      <Select
                        labelId="subject-label"
                        id="subject"
                        disabled={formSubject.disabled}
                        error={formSubject.error}
                        defaultValue={""}
                        label="Предмет"
                        onChange={handleSubject}
                      >
                        <MenuItem value={""} disabled>
                          <em>Избери предмет...</em>
                        </MenuItem>
                        <MenuItem value={"support"}>Поддршка</MenuItem>
                        <MenuItem value={"question"}>Прашање</MenuItem>
                        <MenuItem value={"feedback"}>Критика</MenuItem>
                        <MenuItem value={"problem"}>Проблем</MenuItem>
                        <MenuItem value={"else"}>Друго</MenuItem>
                      </Select>
                      <FormHelperText sx={{ mt: 0 }}>
                        {formSubject.message}
                      </FormHelperText>
                    </FormControl>

                    <TextField
                      multiline
                      //rows={4}
                      fullWidth
                      id="message"
                      label="Порака"
                      variant="outlined"
                      disabled={formMessage.disabled}
                      error={formMessage.error}
                      helperText={formMessage.message}
                      onKeyUp={handleMessage}
                    />

                    <FormControl
                      sx={{ minWidth: { xs: "70%", sm: 250, md: 180 } }}
                    >
                      <InputLabel id="answer-label">Одговор</InputLabel>
                      <Select
                        labelId="answer-label"
                        id="answer"
                        disabled={formAnswer.disabled}
                        error={formAnswer.error}
                        defaultValue={""}
                        label="Предмет"
                        onChange={handleAnswer}
                      >
                        <MenuItem value={""} disabled>
                          <em>Како да ти одговориме...</em>
                        </MenuItem>
                        <MenuItem value={"email"}>Пo Е-mail</MenuItem>
                        <MenuItem value={"sms"} disabled={!formPhone.required}>
                          По SMS
                        </MenuItem>
                        <MenuItem value={"call"} disabled={!formPhone.required}>
                          Телефонски повик
                        </MenuItem>
                      </Select>
                      <FormHelperText sx={{ mt: 0 }}>
                        {formAnswer.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>

                  <Box fullWidth mt={4}>
                    <FormControl>
                      <Button
                        variant="contained"
                        size="large"
                        type="submit"
                        disableElevation
                        disabled={form.buttonDisabled}
                      >
                        Испрати
                      </Button>
                    </FormControl>
                  </Box>
                </form>
              )}
            </Paper>
          </Stack>
          <Box sx={{ position: "absolute", visibility: "hidden", opacity: 0 }}>
            <ReCAPTCHA
              ref={reCaptchaRef}
              size="invisible"
              sitekey={global.config.recaptcha.key}
              data-callback="callback"
              //badge="inline"
            />
          </Box>
        </Container>
      </Box>

      <Container maxWidth={"xl"}></Container>
    </React.Fragment>
  );
}

/*

<Container maxWidth={"lg"}>
          <Typography
            variant={"h5"}
            component={"h1"}
            sx={{
              fontWeight: ({ typography }) => typography.fontWeightBold,
              color: ({ palette }) => palette.primary.contrastText,
              my: 2,
            }}
          >
            Контакт
          </Typography>
        </Container>
*/
