import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import gyCardWinter2021 from "../Promotion/Goodyear/gy_card_winter_2021.min.jpg";
import duCardWinter2021 from "../Promotion/Dunlop/du_card_winter_2021.min.jpg";
import fuCardWinter2021 from "../Promotion/Fulda/fu_card_winter_2021.min.jpg";
import gyLogo from "../Promotion/Goodyear/gy_h_logo.min.png";
import duLogo from "../Promotion/Dunlop/du_h_logo.min.png";
import fuLogo from "../Promotion/Fulda/fu_h_logo.min.png";

import TireGYEF1A6 from "../Promotion/Goodyear/GYEF1A6/GYEF1A6_tire.webp";
import BackgroundCarGYEF1A6 from "../Promotion/Goodyear/GYEF1A6/GYEF1A6_bg_car.webp";
import GoGoodyearLogo from "../Promotion/Goodyear/GOGY.svg";

import BreakingGYEF1A6 from "../Promotion/Goodyear/GYEF1A6/GY_ASY6_ICONS_CMYK_BRAKING_WHT_PRINT_AW.svg";
import HandlingGYEF1A6 from "../Promotion/Goodyear/GYEF1A6/GY_ASY6_ICONS_CMYK_HANDLING_WHT_PRINT_AW.svg";
import GripGYEF1A6 from "../Promotion/Goodyear/GYEF1A6/GY_ASY6_ICONS_CMYK_GRIP_WHT_PRINT_AW.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold + "!important",
  },
  boxPromotion: {
    width: "100%",
    background: theme.palette.box.stretched.background,
    pointerEvents: "none",
    textAlign: "center",
  },
  containerPromotion: {},
  card: {
    background: theme.palette.card.background.default,
    "&:hover": {
      background: theme.palette.card.background.hover,
    },
  },
}));

export default function Home() {
  const navigate = useNavigate();
  const styles = useStyles();

  return (
    <React.Fragment>
      <Box mb={3}>
        <Card
          className={styles.card}
          elevation={0}
          onClick={() => navigate("/gy/ef1a6")}
          sx={{ borderRadius: 0 }}
          //disableRipple
        >
          <CardActionArea disableRipple>
            <Box
              // Main container
              height={{
                xs: "28rem",
                sm: "29rem",
                md: "32rem",
                lg: "38rem",
              }}
              sx={{
                //backgroundColor: "rgb(13, 13, 13)",
                //background: "radial-gradient(circle, rgb(13, 13, 13) 50%, rgb(50, 50, 50) 100%)",

                backgroundImage: `url(${BackgroundCarGYEF1A6})`,
                backgroundSize: "cover",
                backgroundPosition: { xs: "40% 90%", sm: "20% 0%" },
                backgroundRepeat: "no-repeat",

                cursor: "pointer",
              }}
            >
              <CardMedia
                // Logo
                component="div"
                sx={{
                  position: "absolute",
                  left: { xs: "-15%", sm: "-13%", xl: "-5%" },
                  bottom: { xs: "64%", sm: "8%", lg: "10%" },
                  height: { xs: 290, sm: 200, md: 140, lg: 165, xl: 180 },
                  width: { xs: "116%", sm: "67%", md: "65%", xl: "65%" },
                  backgroundSize: "contain",
                  backgroundPosition: { xs: "bottom", xl: "left" },
                  zIndex: { xs: 1, sm: 2 },
                }}
                image={GoGoodyearLogo}
                alt="Go Goodyear"
              />

              <Stack
                direction="row"
                justifyContent="flex-end"
                //alignItems={{ xs: "center", sm: "top", md: "top", lg: "top" }}
                alignItems={{ xs: "flex-start", sm: "flex-end" }}
                pading={0}
                pt={{ xs: 14, sm: 0, lg: 0 }}
              >
                <CardMedia
                  // Tire
                  component="div"
                  sx={{
                    //opacity: 0.8,
                    width: { xs: 290, sm: 350, md: 390, lg: 450 },
                    height: { xs: 400, sm: 740 },
                    backgroundSize: "contain",
                    backgroundPosition: {
                      xs: "center",
                      sm: "top",
                      lg: "center",
                    },
                    mt: { xs: 0, sm: 7, lg: 0 },
                    ml: { xs: -4, sm: 0 },
                    pointerEvents: "none",
                    zIndex: 1,
                    //backgroundColor: "red",
                  }}
                  image={TireGYEF1A6}
                  alt="Goodyear Eagle F1 Asymmetric 6"
                />

                <Box
                  // Blue box container
                  sx={{
                    //backgroundColor: "red",
                    width: { xs: "75%", sm: "52%" },
                    height: { xs: 461, sm: 658, md: 637, lg: 574 },
                    ml: { xs: "-12%", sm: "-8%" },
                    mt: { xs: 10, sm: 0 },
                  }}
                >
                  <Box
                    // Blue gradient
                    sx={{
                      width: "100%",
                      height: { xs: "40%", sm: "39%", md: "45%", lg: "60%" },
                      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#016eb6+0,004991+100 */

                      background: "rgb(1,110,182)",
                      // eslint-disable-next-line
                      background:
                        "-moz-radial-gradient(center, ellipse cover,  rgba(1,110,182,1) 0%, rgba(0,73,145,1) 100%)",
                      // eslint-disable-next-line
                      background:
                        "-webkit-radial-gradient(center, ellipse cover,  rgba(1,110,182,1) 0%,rgba(0,73,145,1) 100%)",
                      // eslint-disable-next-line
                      background:
                        "radial-gradient(ellipse at center,  rgba(1,110,182,1) 0%,rgba(0,73,145,1) 100%)",
                    }}
                  >
                    <Typography
                      variant="h2"
                      component="h1"
                      sx={{
                        textAlign: "left",
                        ml: { xs: "20%", md: "19%", lg: "19%" },
                        fontWeight: 700,
                        fontStyle: "italic",
                        //py: { md: 2 },
                        pt: { xs: 1.25, sm: 1.5, md: 1.25, lg: 1.4 },
                        pl: { xs: 0.5, sm: 0 },
                        color: "#ffffff",
                        //fontSize: { md: "3rem", lg: "3.75rem" },
                        fontSize: ({ typography }) => ({
                          xs: "1.8rem",
                          sm: "2.8rem",
                          md: "2.6rem",
                          lg: "3.1rem",
                        }),
                        lineHeight: { xs: 1.1 },
                      }}
                    >
                      ПОДГОТ
                      <Box
                        sx={{
                          display: { xs: "inline", md: "none" },
                          "&:before": {
                            content: '"-"',
                          },
                        }}
                        variant="span"
                      />
                      <Box
                        sx={{
                          display: { xs: "block", md: "none" },
                        }}
                        variant="br"
                      />
                      ВЕНА{" "}
                      <Box
                        sx={{
                          color: ({ palette }) => palette.secondary.main,
                          display: "inline",
                        }}
                        variant="span"
                      >
                        <Box
                          sx={{
                            display: { sm: "block", md: "block" },
                          }}
                          variant="br"
                        />
                        ЗА... СÈ
                      </Box>
                      ?
                    </Typography>

                    <Box
                      // Blue fade
                      sx={{
                        position: "absolute",
                        bottom: { xs: "16%", sm: "15%", md: "13%", lg: "16%" },
                        width: { xs: "60%", sm: "52%" },
                        pb: { xs: 1.5, sm: 2, lg: 2.5 },
                        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00309d+0,00309d+100&1+0,0+100 */
                        background: "rgb(0,48,157)",
                        // eslint-disable-next-line
                        background:
                          "-moz-linear-gradient(left,  rgba(0,48,157,1) 0%, rgba(0,48,157,0) 100%)",
                        // eslint-disable-next-line
                        background:
                          "-webkit-linear-gradient(left,  rgba(0,48,157,1) 0%,rgba(0,48,157,0) 100%)",
                        // eslint-disable-next-line
                        background:
                          "linear-gradient(to right,  rgba(0,48,157,1) 0%,rgba(0,48,157,0) 100%)",
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                          textAlign: "left",
                          ml: { xs: "20%", md: "19%", lg: "19%" },
                          fontWeight: 700,
                          fontStyle: "italic",
                          pt: { xs: 1.5, sm: 2, lg: 2.5 },
                          pl: { xs: 2, sm: 0 },
                          color: "#ffffff",
                          fontSize: ({ typography }) => ({
                            xs: typography.h6.fontSize,
                            sm: typography.h5.fontSize,
                            lg: typography.h4.fontSize,
                          }),
                        }}
                      >
                        EAGLE F1
                        <Box
                          component="br"
                          sx={{ display: { sm: "block", md: "none" } }}
                        />{" "}
                        ASYMMETRIC 6
                      </Typography>

                      <Stack
                        // Tire Info After this...
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        //spacing={2}
                        sx={{
                          display: { xs: "none", md: "flex" },
                          ml: { md: "19%", lg: "16%" },
                          pt: 2,
                          width: { md: "76%", lg: "80%" },
                          pointerEvents: "none",
                          //backgroundColor: "purple",
                        }}
                      >
                        <Stack
                          // Breaking
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <CardMedia
                            component="div"
                            sx={{
                              width: { md: 70, lg: 80 },
                              height: { md: 70, lg: 80 },
                              //backgroundColor: "red",
                            }}
                            image={BreakingGYEF1A6}
                            alt="Goodyear Eagle F1 Asymmetric 6"
                          />
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontStyle: "italic",
                              color: "#ffffff",
                              fontSize: ({ typography }) => ({
                                xs: typography.h6.fontSize,
                                sm: "1.0rem",
                                lg: typography.h6.fontSize,
                              }),
                            }}
                          >
                            КОЧЕЊЕ
                          </Typography>
                        </Stack>

                        <Stack
                          // Handling
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <CardMedia
                            component="div"
                            sx={{
                              width: { md: 70, lg: 80 },
                              height: { md: 70, lg: 80 },
                              //backgroundColor: "red",
                            }}
                            image={HandlingGYEF1A6}
                            alt="Goodyear Eagle F1 Asymmetric 6"
                          />
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontStyle: "italic",
                              color: "#ffffff",
                              fontSize: ({ typography }) => ({
                                xs: typography.h6.fontSize,
                                sm: "1.0rem",
                                lg: typography.h6.fontSize,
                              }),
                            }}
                          >
                            УПРАВУВАЊЕ
                          </Typography>
                        </Stack>

                        <Stack
                          // Grip
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          <CardMedia
                            component="div"
                            sx={{
                              width: { md: 70, lg: 80 },
                              height: { md: 70, lg: 80 },
                              //backgroundColor: "red",
                            }}
                            image={GripGYEF1A6}
                            alt="Goodyear Eagle F1 Asymmetric 6"
                          />
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontStyle: "italic",
                              color: "#ffffff",
                              fontSize: ({ typography }) => ({
                                xs: typography.h6.fontSize,
                                sm: "1.0rem",
                                lg: typography.h6.fontSize,
                              }),
                            }}
                          >
                            СЛЕПУВАЊЕ
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    // Yellow gradient
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      width: { xs: "69%", sm: "52%" },
                      height: { xs: "16%", sm: "15%", md: "13%", lg: "16%" },
                      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffcd00+0,ffcd00+100&0+0,1+100 */
                      background: "rgb(255,205,0)",
                      // eslint-disable-next-line
                      background:
                        "-moz-linear-gradient(left,  rgba(255,205,0,0) 0%, rgba(255,205,0,1) 60%)",
                      // eslint-disable-next-line
                      background:
                        "-webkit-linear-gradient(left,  rgba(255,205,0,0) 0%,rgba(255,205,0,1) 60%)",
                      // eslint-disable-next-line
                      background:
                        "linear-gradient(to right,  rgba(255,205,0,0) 0%,rgba(255,205,0,1) 60%)",
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        textAlign: "right",
                        marginRight: "5%",
                        fontWeight: 700,
                        fontStyle: "italic",
                        //py: { lg: 2 },
                        color: "#000000",
                        lineHeight: { xs: 4.1, sm: 3, lg: 3 },
                        fontSize: ({ typography }) => ({
                          xs: "1.1rem",
                          sm: typography.h5.fontSize,
                          lg: typography.h4.fontSize,
                        }),
                      }}
                    >
                      ДОЗНАЈ ПОВЕЌЕ
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
      </Box>

      <Container maxWidth={"xl"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              className={styles.card}
              elevation={0}
              onClick={() => navigate("/promo/goodyear/winter")}
            >
              <CardActionArea>
                <CardMedia
                  component="div"
                  sx={{ height: { xs: 290, sm: 250, md: 328 } }}
                  image={gyCardWinter2021}
                  alt="Goodyear Winter Range 2021"
                />
                <Box bgcolor="primary.main">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box py={1}>
                      <img src={gyLogo} alt="Goodyear Logo" height={50} />
                    </Box>
                  </Stack>
                </Box>

                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Новата SAVA
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vinga of Sweden ќебе со 4 гуми од 16" и поголеми
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card
              className={styles.card}
              elevation={0}
              onClick={() => navigate("/promo/dunlop/winter")}
            >
              <CardActionArea>
                <CardMedia
                  component="div"
                  sx={{ height: { xs: 290, sm: 250, md: 328 } }}
                  image={duCardWinter2021}
                  alt="Dunlop Winter Range 2021"
                />
                <Box bgcolor="secondary.main">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box py={1}>
                      <img src={duLogo} alt="Dunlop Logo" height={50} />
                    </Box>
                  </Stack>
                </Box>

                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Гратис филтер со 5L масло
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vinga of Sweden ќебе со 4 гуми од 16" и поголеми
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card
              className={styles.card}
              elevation={0}
              onClick={() => navigate("/promo/fulda/winter")}
            >
              <CardActionArea>
                <CardMedia
                  component="div"
                  sx={{ height: { xs: 290, sm: 250, md: 328 } }}
                  image={fuCardWinter2021}
                  alt="Fulda Winter Range 2021"
                />
                <Box bgcolor="primary.contrastText">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box py={1}>
                      <img src={fuLogo} alt="Fulda Logo" height={50} />
                    </Box>
                  </Stack>
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Подарок кабли со Fulda гуми
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Goodyear LED кабли со 4 гуми од 16" и поголеми
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

// <FontAwesomeIcon icon={faBars} />
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/pro-solid-svg-icons";

/**
 
      <Box
        className={styles.boxQuote}
        paddingY={{ xs: 5, sm: 6 }}
        marginY={{ xs: 6 }}
      >
        <Container maxWidth={"xl"} className={styles.containerQuote}>
          <Typography
            className={styles.title}
            variant={"h4"}
            component={"h1"}
            //fontWeight={"bold"}
            mt={3}
          >
            Побарај понуда...
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button size="large" variant="contained" disableElevation>
              Понуда за гуми
            </Button>

            <Button size="large" variant="contained" disableElevation>
              Понуда за сервис
            </Button>
          </Stack>
        </Container>
      </Box>


      
              default height
              height={{
                xs: "30rem",
                sm: "28rem",
                md: "33rem",
              }}
             








 <Box
                  sx={{
                    pt: { xs: 5, sm: 16, md: 0, lg: 0 },
                    pb: { xs: 0, sm: 3, md: 5, lg: 7 },
                    pl: { xs: 0, sm: 3, md: 3, lg: 5 },
                  }}
                >
                  <CardMedia
                    component="div"
                    image={gyLogo}
                    alt="Goodyear Logo"
                    sx={{
                      backgroundSize: "95%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      height: { xs: 50, sm: 50, md: 65, lg: 80 },
                      width: { xs: "80%", sm: 254, md: 331, lg: 380 },
                      mx: { xs: "10%", sm: 0, md: 0, lg: 0 },
                      backgroundColor: ({ palette }) => palette.primary.main,
                      borderRadius: 1,
                    }}
                  />
                  <Typography
                    //variant="h2"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: "1",
                      fontSize: {
                        xs: "2.2rem",
                        sm: "2.1rem",
                        md: "2.8rem",
                        lg: "3.5rem",
                      },
                      textAlign: {
                        xs: "center",
                        sm: "left",
                      },
                      pr: { xs: 0, sm: 5, md: 6, lg: 6 },
                      mt: { xs: 3, sm: 3, md: 4, lg: 5 },
                      ml: { xs: 0, sm: 1, md: 1, lg: 1 },
                    }}
                    color="primary.contrastText"
                    gutterBottom
                  >
                    <em>
                      Eagle F1
                      <br />
                      Asymmetric 6
                    </em>
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary.contrastText"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      textAlign: {
                        xs: "center",
                        sm: "left",
                      },
                      mt: { xs: 2, sm: 0 },
                      ml: { sm: 1, md: 1, lg: 1 },
                      width: { sm: 180, md: "auto", lg: "auto" },
                    }}
                  >
                    <em>Ултра-високи перформанси.</em>
                  </Typography>
                </Box>

               
 */
