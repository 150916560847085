import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faSnowflake,
  faSunDust,
  faTrophy,
  faTrophyAlt,
} from "@fortawesome/pro-solid-svg-icons";

import gyLogo from "./gy_h_logo.min.png";
import gyWinter2021 from "./gyCoverWinter2021.min.jpg";
import gyUltraGripPerfPlus from "./gy_ug_perf_plus.min.jpg";
import gyUltraGrip9Plus from "./gy_ug_9_plus.min.jpg";
import gyVectorGen3 from "./gy_vec_gen3.min.jpg";
import vulcoBlanket2021 from "./vulco_blanket_2021.min.png";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${gyWinter2021})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  titleHighlight: {
    fontWeight: theme.typography.fontWeightBold + "!important",
    color: theme.palette.primary.contrastText,
  },
  boxHighlight: {
    width: "100%",
    background: theme.palette.primary.main,
    //background: theme.palette.divider,
    pointerEvents: "none",
    textAlign: "center",
  },
  accordionSummaryTitle: {
    color: theme.palette.accordion.title,
  },
  accordionSummaryIcon: {
    color: theme.palette.accordion.icon,
  },
}));

export default function BlanketWinter2021() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        className={styles.image}
        height={{
          xs: "40vh",
          sm: "48vh",
          md: "55vh",
          xl: "52vh",
        }}
        marginTop={{
          xs: 7,
          sm: 7,
        }}
      />
      <Box
        className={styles.boxHighlight}
        paddingTop={{ xs: 4.5 }}
        paddingBottom={{ xs: 2.5 }}
        marginBottom={{ xs: 3 }}
      >
        <Container maxWidth={"xl"}>
          <img src={gyLogo} alt="Goodyear Logo" height={70} />
          <Typography
            className={styles.titleHighlight}
            variant={"h6"}
            component={"h1"}
            my={2}
            mt={1}
            px={4}
          >
            Супериорна контрола во сите зимски услови!
          </Typography>
        </Container>
      </Box>

      <Container maxWidth={"xl"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          mb={3}
        >
          <Grid item xs={6} md={4} lg={4}>
            <Card className={styles.card} elevation={0}>
              <CardMedia
                component="img"
                height={{ xs: 500, md: 400, lg: 350 }}
                image={gyVectorGen3}
                alt="Goodyear Vector 4Seasons Gen-3"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Goodyear Vector 4Seasons Gen-3
                </Typography>
                <Stack
                  spacing={1}
                  justifyContent="flex-start"
                  direction={{
                    xs: "column",
                    xl: "row",
                  }}
                >
                  <Chip
                    icon={<FontAwesomeIcon icon={faTrophyAlt} fixedWidth />}
                    color="primary"
                    label="ПОБЕДНИК"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Chip
                    icon={<FontAwesomeIcon icon={faSunDust} fixedWidth />}
                    variant="outlined"
                    label="ЦЕЛОСЕЗОНСКА"
                    sx={{ fontWeight: "bold" }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <Card className={styles.card} elevation={0}>
              <CardMedia
                component="img"
                height={{ xs: 500, md: 400, lg: 350 }}
                image={gyUltraGrip9Plus}
                alt="Goodyear UltraGrip 9+"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Goodyear UltraGrip 9+
                </Typography>
                <Stack
                  spacing={1}
                  justifyContent="flex-start"
                  direction={{
                    xs: "column",
                    xl: "row",
                  }}
                >
                  <Chip
                    icon={<FontAwesomeIcon icon={faTrophyAlt} fixedWidth />}
                    color="primary"
                    label="ПОБЕДНИК"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Chip
                    icon={<FontAwesomeIcon icon={faSnowflake} fixedWidth />}
                    variant="outlined"
                    label="M+S"
                    sx={{ fontWeight: "bold" }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <Card className={styles.card} elevation={0}>
              <CardMedia
                component="img"
                height={{ xs: 500, md: 400, lg: 350 }}
                image={gyUltraGripPerfPlus}
                alt="Goodyear UltraGrip Performance+"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Goodyear UltraGrip Performance+
                </Typography>
                <Stack
                  spacing={1}
                  justifyContent="flex-start"
                  direction={{
                    xs: "column",
                    xl: "row",
                  }}
                >
                  <Chip
                    icon={<FontAwesomeIcon icon={faTrophy} fixedWidth />}
                    label="ВИСОКО ПРЕПОРАЧАНА"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Chip
                    icon={<FontAwesomeIcon icon={faSnowflake} fixedWidth />}
                    variant="outlined"
                    label="M+S"
                    sx={{ fontWeight: "bold" }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={"md"}>
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom paragraph px={2} pt={3}>
            Безгрижноста на зимските патишта е непроценлива. Чувството на
            контрола без разлика на студот, снегот или мразот, како и краткиот
            пат на кочење на влажни и суви патишта се главните причини поради
            кои возачите се одлучуваат за премиум гуми. Супериорните возни
            карактеристики редовно се докажуваат и со голем број независни
            тестови.
          </Typography>
          <Typography variant="body1" gutterBottom paragraph px={2}>
            Тоа го покажуваат и{" "}
            <Box component="span" fontWeight="fontWeightMedium">
              овогодишните рангирања на една од најценетите публикации за
              тестирање во индустријата ADAC, а нивната извонредност ја потврди
              и AMZS.
            </Box>
            .
          </Typography>
          <Typography variant="body1" gutterBottom paragraph px={2}>
            Ова е достигнување кое за Goodyear значи{" "}
            <Box component="span" fontWeight="fontWeightMedium">
              признание за технологијата на најновите понудени гуми
            </Box>
            . Иновациите како што е технологијата Winter Grip ги одржуваат
            Goodyear гумите (UltraGrip 9+ и Performance+) на самиот врв, во кој
            семејството UltraGrip победува со децении.
          </Typography>
          <Typography variant="body1" paragraph px={2} pb={3}>
            За да ја направиме одлуката уште полесна оваа зима,{" "}
            <Box component="span" fontWeight="fontWeightMedium">
              со истовремено купување 4 гуми Goodyear M+S или целосезонски 16"
              или поголеми во Vulco Прилеп до 18.12.2021, ќе ви подариме топло и
              меко ќебе од реномираниот бренд Vinga од Шведска
            </Box>
            .
          </Typography>
        </Paper>
      </Container>

      <Box
        className={styles.boxHighlight}
        py={{ xs: 5 }}
        mt={3}
        marginBottom={{ xs: 3 }}
      >
        <Container maxWidth={"xl"}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 4, md: 4 }}
            pr={{ xs: 0, md: 3 }}
          >
            <CardMedia
              sx={{
                height: { xs: 250, md: 280 },
                width: { xs: 300, md: 320 },
              }}
              image={vulcoBlanket2021}
            />
            <Box>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems={{ xs: "center", md: "flex-start" }}
                spacing={{ xs: 2, md: 1 }}
              >
                <Typography
                  className={styles.titleHighlight}
                  variant={"h4"}
                  component={"h3"}
                  color="secondary"
                >
                  ПОДАРОК
                </Typography>
                <Typography
                  className={styles.titleHighlight}
                  variant={"h5"}
                  component={"h4"}
                  textAlign="left"
                >
                  Vinga of Sweden ќебе
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="md">
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={
              <FontAwesomeIcon
                className={styles.accordionSummaryIcon}
                icon={faChevronDown}
                fixedWidth
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              className={styles.accordionSummaryTitle}
              variant={"button"}
            >
              Правила
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={"body2"} paragraph>
              1. Промоцијата трае до 18.12.2021 или до крајот на залихите. Само
              во Vulco Прилеп.
            </Typography>
            <Typography variant={"body2"} paragraph>
              2. Промоцијата важи за истовремено купување на четири Goodyear M+S
              или целосезонски гуми од 16" или поголеми.
            </Typography>
            <Typography variant={"body2"} paragraph>
              3. Промотивната кампања важи за физички и правни лица.
            </Typography>
            <Typography variant={"body2"} paragraph>
              4. Едно правно или физичко лице може да добие најмногу два
              подароци дококу истовремено купи осум или повеќе гуми.
            </Typography>
            <Typography variant={"body2"} paragraph>
              5. Промотивен подарок е јорган од Шведскиот бренд Vingа.
            </Typography>
            <Typography variant={"body2"} paragraph>
              6. Промотивниот подарок не може да се замени за готовина или друг
              производ.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>

      <Container maxWidth={"md"}></Container>
    </React.Fragment>
  );
}
