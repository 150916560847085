module.exports = global.config = {
  title_base: "Vulco Прилеп",
  recaptcha: { key: "6LecqhUdAAAAAIkmS1SdnK7IgCtO_BfnWlVML3TD" },
  country: {
    MK: {
      prefix: "389",
      lengthMin: 8,
      lengthMax: 8,
      localPrefix: [
        {
          prefix: "2",
          accepted: true,
          mobile: false,
          helper: "02 XXXX XXX",
        },
        {
          prefix: "3",
          accepted: false,
          mobile: false,
          helper: "03X XXX XXX",
        },
        {
          prefix: "31",
          accepted: true,
          mobile: false,
          helper: "031 XXX XXX",
        },
        {
          prefix: "32",
          accepted: true,
          mobile: false,
          helper: "032 XXX XXX",
        },
        {
          prefix: "33",
          accepted: true,
          mobile: false,
          helper: "033 XXX XXX",
        },
        {
          prefix: "34",
          accepted: true,
          mobile: false,
          helper: "034 XXX XXX",
        },
        {
          prefix: "4",
          accepted: false,
          mobile: false,
          helper: "04X XXX XXX",
        },
        {
          prefix: "42",
          accepted: true,
          mobile: false,
          helper: "042 XXX XXX",
        },
        {
          prefix: "43",
          accepted: true,
          mobile: false,
          helper: "043 XXX XXX",
        },
        {
          prefix: "44",
          accepted: true,
          mobile: false,
          helper: "044 XXX XXX",
        },
        {
          prefix: "45",
          accepted: true,
          mobile: false,
          helper: "045 XXX XXX",
        },
        {
          prefix: "46",
          accepted: true,
          mobile: false,
          helper: "046 XXX XXX",
        },
        {
          prefix: "47",
          accepted: true,
          mobile: false,
          helper: "047 XXX XXX",
        },
        {
          prefix: "48",
          accepted: true,
          mobile: false,
          helper: "048 XXX XXX",
        },
        {
          prefix: "7",
          accepted: false,
          mobile: true,
          helper: "07X XXX XXX",
        },
        {
          prefix: "70",
          accepted: true,
          mobile: true,
          helper: "070 XXX XXX",
        },
        {
          prefix: "71",
          accepted: true,
          mobile: true,
          helper: "071 XXX XXX",
        },
        {
          prefix: "72",
          accepted: true,
          mobile: true,
          helper: "072 XXX XXX",
        },
        {
          prefix: "73",
          accepted: true,
          mobile: true,
          helper: "073 XXX XXX",
        },
        {
          prefix: "74",
          accepted: true,
          mobile: true,
          helper: "074 XXX XXX",
        },
        {
          prefix: "75",
          accepted: true,
          mobile: true,
          helper: "075 XXX XXX",
        },
        {
          prefix: "76",
          accepted: true,
          mobile: true,
          helper: "076 XXX XXX",
        },
        {
          prefix: "77",
          accepted: true,
          mobile: true,
          helper: "077 XXX XXX",
        },
        {
          prefix: "78",
          accepted: true,
          mobile: true,
          helper: "078 XXX XXX",
        },
        {
          prefix: "79",
          accepted: true,
          mobile: true,
          helper: "079 XXX XXX",
        },
      ],
    },
  },
};
