import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Stack,
} from "@mui/material";

import TireGYEF1A6 from "../GYEF1A6/GYEF1A6_tire2.webp";

import BreakingGYEF1A6 from "../GYEF1A6/GY_ASY6_ICONS_CMYK_BRAKING_WHT_PRINT_AW.svg";
import HandlingGYEF1A6 from "../GYEF1A6/GY_ASY6_ICONS_CMYK_HANDLING_WHT_PRINT_AW.svg";
import GripGYEF1A6 from "../GYEF1A6/GY_ASY6_ICONS_CMYK_GRIP_WHT_PRINT_AW.svg";
import GoGoodyearLogo from "../GOGY.svg";

import TestAutoBildGYEF1A6 from "../GYEF1A6/TESTS/eagf1as6-auto-bild-test-winner-2022-en-testresult.jpeg";
import TestAutoMotoSportGYEF1A6 from "../GYEF1A6/TESTS/eagf1as6-auto-motor-und-sport-test-winner-2022-en-testresult.jpeg";
import TestTyreReviewsGYEF1A6 from "../GYEF1A6/TESTS/eagf1as6-tyrereview-2022-en-testresult.jpeg";

export default function GYEF1A6withGift() {
  return (
    <React.Fragment>
      <Box
        // Header
        sx={{
          height: {
            xs: "30rem",
            sm: "29rem",
            md: "32rem",
            lg: "38rem",
          },
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundColor: "black",
          //overflow: "hidden",
        }}
      >
        <CardMedia
          // Tire
          component="div"
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: {
              xs: "36rem",
              sm: "38rem",
              md: "46rem",
              lg: "52rem",
            },
            backgroundSize: {
              xs: "auto 65%",
              sm: "auto 80%",
              md: "auto 85%",
              lg: "auto 85%",
            },
            backgroundPosition: "center bottom",
            //backgroundRepeat: "repeat-x",

            pointerEvents: "none",
            zIndex: 1,
          }}
          image={TireGYEF1A6}
          alt="Goodyear Eagle F1 Asymmetric 6"
        />
        <Box
          sx={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: { xs: "30rem", sm: "29rem", md: "32rem", lg: "38rem" },
            background: "transparent",
            // eslint-disable-next-line
            background:
              "-moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 97%, rgba(0,0,0,0) 100%)",
            // eslint-disable-next-line
            background:
              "-webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 97%,rgba(0,0,0,0) 100%)",
            // eslint-disable-next-line
            background:
              "linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 97%,rgba(0,0,0,0) 100%)",
            zIndex: 2,
          }}
        />
        <CardMedia
          // Logo
          component="div"
          sx={{
            position: "absolute",
            ml: { xs: "-10%", sm: -8, md: -9, lg: -10 },
            top: { xs: "6rem", sm: "7rem", md: "7rem" },
            height: { xs: 120, sm: 120, md: 140, lg: 160 },
            width: { xs: "105%", sm: 550, md: 700 },
            backgroundSize: "contain",
            backgroundPosition: "left",
            zIndex: 3,
            //backgroundColor: "red",
          }}
          image={GoGoodyearLogo}
          alt="Go Goodyear"
        />
      </Box>

      <Box
        sx={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          overflow: "hidden",

          background: "rgb(1,110,182)",
          // eslint-disable-next-line
          background:
            "-moz-radial-gradient(center, ellipse cover,  rgba(1,110,182,1) 0%, rgba(0,73,145,1) 100%)",
          // eslint-disable-next-line
          background:
            "-webkit-radial-gradient(center, ellipse cover,  rgba(1,110,182,1) 0%,rgba(0,73,145,1) 100%)",
          // eslint-disable-next-line
          background:
            "radial-gradient(ellipse at center,  rgba(1,110,182,1) 0%,rgba(0,73,145,1) 100%)",
        }}
      >
        <Stack
          // Tire Info After this...
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 2, sm: 7, md: 10 }}
          sx={{
            py: 3,
            pt: { xs: 10, sm: 15, md: 25 },
            width: "100%",
            pointerEvents: "none",
            background: "rgb(0,48,157)",
            // eslint-disable-next-line
            background:
              "-moz-linear-gradient(left, rgba(0,48,157,1) 0%, rgba(0,48,157,0) 100%)",
            // eslint-disable-next-line
            background:
              "-webkit-linear-gradient(left, rgba(0,48,157,1) 0%,rgba(0,48,157,0) 100%)",
            // eslint-disable-next-line
            background:
              "linear-gradient(to right, rgba(0,48,157,1) 0%,rgba(0,48,157,0) 100%)",
          }}
        >
          <Stack
            // Breaking
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <CardMedia
              component="div"
              sx={{
                width: { xs: 70, lg: 80 },
                height: { xs: 70, lg: 80 },
                //backgroundColor: "red",
              }}
              image={BreakingGYEF1A6}
              alt="Goodyear Eagle F1 Asymmetric 6"
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontStyle: "italic",
                color: "#ffffff",
                fontSize: ({ typography }) => ({
                  xs: typography.h6.fontSize,
                  lg: typography.h5.fontSize,
                }),
              }}
            >
              КОЧЕЊЕ
            </Typography>
          </Stack>

          <Stack
            // Handling
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <CardMedia
              component="div"
              sx={{
                width: { xs: 70, lg: 80 },
                height: { xs: 70, lg: 80 },
                //backgroundColor: "red",
              }}
              image={HandlingGYEF1A6}
              alt="Goodyear Eagle F1 Asymmetric 6"
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontStyle: "italic",
                color: "#ffffff",
                fontSize: ({ typography }) => ({
                  xs: typography.h6.fontSize,
                  lg: typography.h5.fontSize,
                }),
              }}
            >
              УПРАВУВАЊЕ
            </Typography>
          </Stack>

          <Stack
            // Grip
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <CardMedia
              component="div"
              sx={{
                width: { xs: 70, lg: 80 },
                height: { xs: 70, lg: 80 },
                //backgroundColor: "red",
              }}
              image={GripGYEF1A6}
              alt="Goodyear Eagle F1 Asymmetric 6"
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontStyle: "italic",
                color: "#ffffff",
                fontSize: ({ typography }) => ({
                  xs: typography.h6.fontSize,
                  lg: typography.h5.fontSize,
                }),
              }}
            >
              СЛЕПУВАЊЕ
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Container maxWidth={"lg"} sx={{ mt: 8 }}>
        <Paper elevation={0} sx={{ background: "transparent" }}>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "2.8rem",
                sm: "4.6rem",
                md: "5.6rem",
                lg: "6rem",
              },
            }}
            gutterBottom
            align="justify"
          >
            Eagle F1{" "}
            <Box
              sx={{
                display: { xs: "block", md: "block", lg: "none" },
              }}
              variant="br"
            />
            Asymmetric 6
          </Typography>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontStyle: "italic",
              fontSize: ({ typography }) => ({
                xs: typography.h4.fontSize,
                sm: typography.h3.fontSize,
              }),
            }}
          >
            Ултра-високи перформанси за влажни и суви патишта.
          </Typography>
        </Paper>
      </Container>

      <Container maxWidth={"lg"} sx={{ mt: 4 }}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          justifyContent={{
            xs: "center",
            sm: "space-between",
            md: "space-around",
            lg: "space-evenly",
          }}
          spacing={2}
          sx={{ width: "100%" }}
          alignItems="flex-start"
          pb={{ xs: 2, sm: 0 }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Card elevation={0} sx={{ width: "100%" }}>
              <CardContent>
                <Typography variant="h5">
                  Dry Contact Plus технологија
                </Typography>
                <Typography sx={{ mt: 1.5 }} variant="body1">
                  The tyre`s contact patch adapts to the driving style and road
                  surface for improved dry performance.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card elevation={0} sx={{ width: "100%" }}>
              <CardContent>
                <Typography variant="h5">
                  Wet Braking Pro технологија
                </Typography>
                <Typography sx={{ mt: 1.5 }} variant="body1">
                  New resin allows the tyre to have more micro-contact with the
                  road which results in improved wet braking.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card elevation={0} sx={{ width: "100%" }}>
              <CardContent>
                <Typography variant="h5">
                  Подготвена за електрични возила
                </Typography>
                <Typography sx={{ mt: 1.5 }} variant="body1">
                  Lower noise emissions and reduced rolling resistance make the
                  tyre a perfect fit for electric vehicles.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          width: "100%",
          mt: 4,
          background: "#ffffff",
        }}
      >
        <Container maxWidth={"lg"}>
          <Typography
            variant="h4"
            component="div"
            color="text.fixed.light.primary"
            pt={4}
            fontWeight={700}
          >
            Што велат резултатите од тестовите...
          </Typography>

          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{
              xs: "center",
              sm: "space-between",
              md: "space-evenly",
            }}
            spacing={0}
            sx={{ width: "100%" }}
            alignItems="flex-start"
            pb={{ xs: 2, sm: 0 }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  width: 250,
                  background: "#ffffff",
                }}
                elevation={0}
              >
                <CardMedia
                  component="img"
                  sx={{ pt: { xs: 4.2 }, px: 8 }}
                  image={TestAutoBildGYEF1A6}
                  alt="Goodyear Eagle F1 Asymmetric 6"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="text.fixed.light.primary"
                  >
                    Auto Bild
                    <br />
                    Издание 13
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.fixed.light.secondary"
                    gutterBottom
                  >
                    Димензија: 245/40R19
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.fixed.light.secondary"
                  >
                    Победник на тестот, 1-ва од 10 тестирани брендови
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  width: 250,
                  background: "#ffffff",
                }}
                elevation={0}
              >
                <CardMedia
                  component="img"
                  sx={{ pt: { xs: 1, sm: 4.2 }, px: 7.5 }}
                  image={TestAutoMotoSportGYEF1A6}
                  alt="Goodyear Eagle F1 Asymmetric 6"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="text.fixed.light.primary"
                  >
                    Auto Motor und Sport
                    <br />
                    Издание 7/2022
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.fixed.light.secondary"
                    gutterBottom
                  >
                    Димензија: 245/45R19
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.fixed.light.secondary"
                  >
                    Победник на тестот, 1-ва од 10 тестирани брендови
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  width: 250,
                  background: "#ffffff",
                }}
                elevation={0}
              >
                <CardMedia
                  component="img"
                  sx={{ pt: { xs: 1, sm: 4.2 }, px: 0 }}
                  image={TestTyreReviewsGYEF1A6}
                  alt="Goodyear Eagle F1 Asymmetric 6"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="text.fixed.light.primary"
                  >
                    TyreReviews
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.fixed.light.secondary"
                    gutterBottom
                  >
                    Димензија: 225/45R17
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.fixed.light.secondary"
                  >
                    Победник на тестот, 1-ва од 10 тестирани брендови
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth={"lg"} sx={{ mt: 4 }}>
        <Typography
          variant="h4"
          component="div"
          color="text.fixed.light.primary"
          mb={4}
          fontWeight={700}
        >
          Гумите во акција
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
        >
          <Card
            elevation={0}
            sx={{
              width: { xs: "100%", sm: 500, md: "50%" },
              height: { xs: 250, sm: 270, md: 250, lg: 300 },
            }}
          >
            <CardMedia
              sx={{
                width: "100%",
                height: "100%",
              }}
              component="iframe"
              frameBorder={0}
              image="https://www.youtube.com/embed/OpS_gdF2x1Q?color=white&modestbranding=0&showinfo=0&rel=0&showinfo=0&disablekb=0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Card>
          <Card
            elevation={0}
            sx={{
              width: { xs: "100%", sm: 500, md: "50%" },
              height: { xs: 250, sm: 270, md: 250, lg: 300 },
            }}
          >
            <CardMedia
              component="iframe"
              sx={{
                width: "100%",
                height: "100%",
              }}
              frameBorder={0}
              image="https://www.youtube.com/embed/FnC_sejquYI?color=white&modestbranding=0&showinfo=0&rel=0&showinfo=0&disablekb=0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Card>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
