import React, { useState, useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Box,
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Alert,
  CardMedia,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import mkLocale from "date-fns/locale/mk";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";

import { makeStyles } from "@mui/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCheck,
  faInfoCircle,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";

import gyCleaningSet from "./gy_cleaning_set_copy.min.png";

const useStyles = makeStyles((theme) => ({
  titleHighlight: {
    fontWeight: theme.typography.fontWeightBold + "!important",
    color: theme.palette.primary.contrastText,
  },
  boxHighlight: {
    width: "100%",
    background: theme.palette.primary.main,
    //background: theme.palette.divider,
    pointerEvents: "none",
    textAlign: "center",
  },
  accordionSummaryTitle: {
    color: theme.palette.accordion.title,
  },
  accordionSummaryIcon: {
    color: theme.palette.accordion.icon,
  },
}));

const country = {
  MK: {
    prefix: {
      country: 389,
      trunk: 0,
    },
    place: [
      { name: "Прилеп", value: "prilep" },
      { name: "Битола", value: "bitola" },
      { name: "Кривогаштани", value: "krivogashtani" },
      { name: "Кавадарци", value: "kavadarci" },
      { name: "Неготино", value: "negotino" },
      { name: "Македонски Брод", value: "makedonski_brod" },
      { name: "Могила", value: "mogila" },
      { name: "Демир Хисар", value: "demir_hisar" },
      { name: "Росоман", value: "rosoman" },
      { name: "Охрид", value: "ohrid" },
      { name: "Струга", value: "struga" },
      { name: "Ресен", value: "resen" },
      { name: "Скопје", value: "skopje" },
      { name: "Куманово", value: "kumanovo" },
      { name: "Тетово", value: "tetovo" },
      { name: "Гостивар", value: "gostivar" },
      { name: "Кичево", value: "kichevo" },
      { name: "Велес", value: "veles" },
      { name: "Струмица", value: "strumica" },
      { name: "Штип", value: "shtip" },
      { name: "Кочани", value: "kochani" },
      { name: "Боговиње", value: "bogovinje" },
      { name: "Радовиш", value: "radovish" },
      { name: "Липково", value: "lipkovo" },
      { name: "Врапчиште", value: "vrapchishte" },
      { name: "Желино", value: "zhelino" },
      { name: "Гевгелија", value: "gevgelija" },
      { name: "Теарце", value: "tearce" },
      { name: "Крива Паланка", value: "kriva_palanka" },
      { name: "Виница", value: "vinica" },
      { name: "Дебар", value: "debar" },
      { name: "Свети Николе", value: "sveti_nikole" },
      { name: "Делчево", value: "delchevo" },
      { name: "Студеничани", value: "studenichani" },
      { name: "Пробиштип", value: "probishtip" },
      { name: "Илинден", value: "ilinden" },
      { name: "Брвеница", value: "brvenica" },
      { name: "Босилово", value: "bosilovo" },
      { name: "Берово", value: "berovo" },
      { name: "Долнени", value: "dolneni" },
      { name: "Василево", value: "vasilevo" },
      { name: "Валандово", value: "valandovo" },
      { name: "Арачиново", value: "arachinovo" },
      { name: "Ново Село", value: "novo_selo" },
      { name: "Јегуновце", value: "jegunovce" },
      { name: "Кратово", value: "kratovo" },
      { name: "Крушево", value: "krushevo" },
      { name: "Богданци", value: "bogdanci" },
      { name: "Маврово и Ростуше", value: "mavrovo_i_rostushe" },
      { name: "Чучер-Сандево", value: "chucher_sandevo" },
      { name: "Петровец", value: "petrovec" },
      { name: "Македонска Каменица", value: "makedonska_kamenica" },
      { name: "Чашка", value: "chashka" },
      { name: "Чешиново-Облешево", value: "cheshinovo_obleshevo" },
      { name: "Центар Жупа", value: "centar_zhupa" },
      { name: "Сопиште", value: "sopishte" },
      { name: "Пехчево", value: "pehchevo" },
      { name: "Дебарца", value: "debarca" },
      { name: "Старо Нагоричане", value: "staro_nagorichane" },
      { name: "Пласница", value: "plasnica" },
      { name: "Демир Капија", value: "demir_kapija" },
      { name: "Ранковце", value: "rankovce" },
      { name: "Зелениково", value: "zelenikovo" },
      { name: "Карбинци", value: "karbinci" },
      { name: "Градско", value: "gradsko" },
      { name: "Новаци", value: "novaci" },
      { name: "Конче", value: "konche" },
      { name: "Дојран", value: "dojran" },
      { name: "Зрновци", value: "zrnovci" },
      { name: "Лозово", value: "lozovo" },
      { name: "Вевчани", value: "vevchani" },
      { name: "Друго", value: "other" },
    ],
  },
};

//var dateToday = new Date();
var dateMax = new Date();
dateMax.setFullYear(dateMax.getFullYear() - 18);

const surveyOptions = [
  { name: "Преку Facebook", value: "fb" },
  { name: "Преку Instagram", value: "ig" },
  { name: "Преку Google", value: "gg" },
  { name: "На Радио", value: "rd" },
  { name: "По Препорака", value: "rc" },
  { name: "Леток/Флаер", value: "lf" },
  { name: "Друго", value: "ot" },
];

export default function CleaningSet2021() {
  const reCaptchaRef = useRef();

  var claimedShowForm = true;
  var claimedAlertAlreadySubmitted = false;

  var claimed = localStorage.getItem("vulcoClaimedCleaningSet2021");
  if (claimed === "y") {
    claimedShowForm = false;
    claimedAlertAlreadySubmitted = true;
  } else {
    claimedShowForm = true;
    claimedAlertAlreadySubmitted = false;
  }

  const [form, setForm] = useState({
    gender: {
      value: "",
      valid: false,
      error: false,
      message: "",
      disabled: false,
    },
    name: {
      first: "",
      last: "",
      valid: false,
      error1: false,
      error2: false,
      message1: "",
      message2: "",
      disabled: false,
    },
    phone: {
      value: "",
      valid: false,
      error: false,
      message: "",
      disabled: false,
    },
    email: {
      value: "",
      valid: false,
      error: false,
      message: "",
      disabled: false,
    },
    place: {
      value: "",
      other: "",
      valid: false,
      error: false,
      message1: "",
      message2: "",
      disabled: false,
    },
    birthday: {
      date: "",
      valid: false,
      error: false,
      message: "",
      disabled: false,
    },
    survey: {
      list: [],
      valid: false,
      error: false,
      message: "",
      disabled: false,
    },
    marketing: {
      checked: false,
      valid: true,
      disabled: false,
    },
    terms: {
      checked: false,
      valid: false,
      error: false,
      message: "",
      disabled: false,
    },

    form: {
      loading: false,
      showForm: claimedShowForm,
      alertAlreadySubmitted: claimedAlertAlreadySubmitted,
      alertSuccessfullySubmitted: false,
      alertSuccessfullySubmittedMessage: "",
      alertError: false,
      alertErrorMessage: "",
    },
  });

  const styles = useStyles();

  const alertSuccessfullySubmitted = () => {
    if (form.form.alertSuccessfullySubmitted) {
      return (
        <Box mb={2}>
          <Alert
            icon={<FontAwesomeIcon icon={faCheck} fixedWidth />}
            severity="success"
            variant="filled"
          >
            {form.form.alertSuccessfullySubmittedMessage}
          </Alert>
        </Box>
      );
    }
  };

  const alertAlreadySubmitted = () => {
    if (form.form.alertAlreadySubmitted) {
      return (
        <Box mb={2}>
          <Alert
            icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
            severity="info"
            variant="filled"
            color="primary"
          >
            Веќе имате аплицирано за ваучер!
          </Alert>
        </Box>
      );
    }
  };

  const alertError = () => {
    if (form.form.alertError) {
      return (
        <Box mb={2}>
          <Alert
            icon={<FontAwesomeIcon icon={faTimes} fixedWidth />}
            severity="error"
            variant="filled"
          >
            {form.form.alertErrorMessage}
          </Alert>
        </Box>
      );
    }
  };

  const handleFormGender = (event) => {
    const {
      target: { value },
    } = event;

    var gender = value;
    var { valid, error, message } = form.gender;

    if (gender.length > 0) {
      valid = true;
      error = false;
      message = "";
    } else {
      valid = false;
      error = true;
      message = "Избери како да ти се обраќаме!";
    }

    setForm({
      ...form,
      gender: {
        value: gender,
        valid,
        error,
        message,
      },
    });
  };

  const handleFormName = (event) => {
    const {
      target: { id, value },
    } = event;
    var { first, last, valid, error1, error2, message1, message2 } = form.name;

    if (id === "name_first" && !(value.length > 45)) {
      if (value.length > 2) {
        first = value;
        valid = true;
        error1 = false;
        message1 = "";
      } else {
        valid = false;
        error1 = true;
        message1 = "Внеси точно име!";
      }
    }

    if (id === "name_last") {
      if (value.length > 2 && !(value.length > 45)) {
        last = value;
        valid = true;
        error2 = false;
        message2 = "";
      } else {
        valid = false;
        error2 = true;
        message2 = "Внеси точно презиме!";
      }
    }

    setForm({
      ...form,
      name: {
        first,
        last,
        valid,
        error1,
        error2,
        message1,
        message2,
      },
    });
  };

  const handleFormPhone = (event) => {
    const {
      target: { value },
    } = event;

    var number = parseInt(value).toString();
    var valid = false;
    var error = false;
    var message = "";

    if (number.length > 0) {
      if (
        [70, 71, 72, 73, 74, 75, 76, 77, 78, 79].filter((item) =>
          number.startsWith(item)
        ).length === 1 &&
        number.length === 8
      ) {
        valid = true;
        error = false;
        message = "";
      } else {
        valid = false;
        error = true;
        message = "Внеси точен мобилен број 07X XXX XXX";
      }
    } else {
      valid = false;
      error = true;
      message = "Внеси го мобилниот број 07X XXX XXX";
    }

    setForm({
      ...form,
      phone: {
        value: parseInt(number),
        valid,
        error,
        message,
      },
    });
  };

  const handleFormEmail = (event) => {
    const {
      target: { value },
    } = event;

    var email = "";
    var valid = false;
    var error = false;
    var message = "";

    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //return ;

    if (regexp.test(value)) {
      email = value;
      valid = true;
      error = false;
      message = "";
    } else {
      valid = false;
      error = true;
      message = "Внеси точна e-mail адреса!";
    }

    setForm({
      ...form,
      email: {
        value: email,
        valid,
        error,
        message,
      },
    });
  };

  const handleFormPlace = (event) => {
    const { target } = event;

    var value = form.place.value;
    var other = form.place.other;
    var valid = false;
    var error = false;
    var message1 = "";
    var message2 = "";

    if (target.id && target.id === "place_other") {
      other = target.value;

      if (other.length <= 2) {
        valid = false;
        error = true;
        message1 = "";
        message2 = "Внеси точно населно место!";
      } else {
        valid = true;
        error = false;
        message1 = "";
        message2 = "";
      }
    } else {
      value = target.value;

      if (value !== "other") {
        other = "";
        message2 = "";
      }

      if (value.length > 0) {
        valid = true;
        error = false;
        message1 = "";
        message2 = "";
      } else {
        valid = false;
        error = true;
        message1 = "Избери место на живеење!";
        message2 = "";
      }
    }

    setForm({
      ...form,
      place: {
        value,
        other,
        valid,
        error,
        message1,
        message2,
      },
    });
  };

  const handleFormBirthday = (event) => {
    var { valid, error, message } = form.birthday;

    var today = new Date();
    var birthDate = new Date(event);
    var age = today.getFullYear() - birthDate.getFullYear();

    if (age >= 18) {
      valid = true;
      error = false;
      message = "";
    } else {
      valid = false;
      error = true;
      message = "Минимална возраст за учество е 18 години!";
    }

    setForm({
      ...form,
      birthday: {
        date: birthDate.toISOString().slice(0, 10),
        valid,
        error,
        message,
      },
    });
  };

  const handleFormSurvey = (event) => {
    const {
      target: { value },
    } = event;

    var valid = false;
    var error = false;
    var message = "";

    var len = value.length;

    if (len === 0 || len < 1) {
      valid = false;
      error = true;
      message = "Избери од понудените опции!";
    } else if (len > 0) {
      valid = true;
      error = false;
      message = "";
    }

    setForm({
      ...form,
      survey: {
        list: value,
        valid,
        error,
        message,
      },
    });
  };

  const handleFormMarketing = (event) => {
    const {
      target: { checked },
    } = event;

    setForm({
      ...form,
      marketing: {
        checked,
      },
    });
  };

  const handleFormTerms = (event) => {
    const {
      target: { checked },
    } = event;

    var error = false;
    var valid = false;

    if (!checked) {
      error = true;
      valid = false;
    } else {
      error = false;
      valid = true;
    }

    setForm({
      ...form,
      terms: {
        checked,
        error,
        valid,
      },
    });
  };

  const handleFormSubmit = async (event) => {
    console.log({ form });

    var disabled = false;
    var loading = false;

    var validScore = 0;

    var errorGender = false;
    var errorName = false;
    var errorPhone = false;
    var errorEmail = false;
    var errorPlace = false;
    var errorBirthday = false;
    var errorSurvey = false;
    var errorTerms = false;

    if (!form.gender.valid) {
      validScore++;
      errorGender = true;
    }
    if (!form.name.valid) {
      validScore++;
      errorName = true;
    }
    if (!form.phone.valid) {
      validScore++;
      errorPhone = true;
    }
    if (!form.email.valid) {
      validScore++;
      errorEmail = true;
    }
    if (!form.place.valid) {
      validScore++;
      errorPlace = true;
    }
    if (!form.birthday.valid) {
      validScore++;
      errorBirthday = true;
    }
    if (!form.survey.valid) {
      validScore++;
      errorSurvey = true;
    }
    if (!form.terms.valid) {
      validScore++;
      errorTerms = true;
    }

    console.log({ validScore });

    if (validScore === 0) {
      disabled = true;
      loading = true;

      // Disable the form and show the loader
      setForm({
        ...form,
        gender: { ...form.gender, disabled },
        name: { ...form.name, disabled },
        phone: { ...form.phone, disabled },
        email: { ...form.email, disabled },
        place: { ...form.place, disabled },
        birthday: { ...form.birthday, disabled },
        survey: { ...form.survey, disabled },
        marketing: { ...form.marketing, disabled },
        terms: { ...form.terms, disabled },
        form: { ...form.form, loading },
      });

      const token = await reCaptchaRef.current.executeAsync();
      console.log({ token });

      axios
        .post("https://api.portal.uni.mk/vulco/campaign/claim", {
          token,
          gender: form.gender.value,
          name: { first: form.name.first, last: form.name.last },
          birthday: form.birthday.date,
          phone: {
            prefix: 389,
            endpoint: form.phone.value,
          },
          email: form.email.value,
          place: {
            administrative: form.place.value,
            other: form.place.other,
          },
          survey: form.survey.list,
          marketing: form.marketing.checked,
        })
        .then((response) => {
          console.info("status", response.status);
          console.info("data", response.data);
          var message = "";
          if (response.status === 201) {
            switch (true) {
              case response.data.p.s && response.data.p.e:
                message = "Успешно! Ваучерот ќе го добиете по SMS и на E-mail!";
                break;
              case !response.data.p.s && response.data.p.e:
                message = "Успешно! Ваучерот ќе го добиете на E-mail!";
                break;
              case response.data.p.s && !response.data.p.e:
                message = "Успешно! Ваучерот ќе го добиете по SMS!";
                break;
              default:
                message = "Успешно! Ви благодариме!";
                break;
            }
            setForm({
              ...form,
              gender: { ...form.gender, disabled },
              name: { ...form.name, disabled },
              phone: { ...form.phone, disabled },
              email: { ...form.email, disabled },
              place: { ...form.place, disabled },
              birthday: { ...form.birthday, disabled },
              survey: { ...form.survey, disabled },
              marketing: { ...form.marketing, disabled },
              terms: { ...form.terms, disabled },
              form: {
                ...form.form,
                loading: false,
                alertError: false,
                alertAlreadySubmitted: false,
                alertSuccessfullySubmitted: true,
                alertSuccessfullySubmittedMessage: message,
                showForm: false,
              },
            });
            // Record local storage
            localStorage.setItem("vulcoClaimedCleaningSet2021", "y");
          } else {
            reCaptchaRef.current.reset();
            disabled = false;
            setForm({
              ...form,
              gender: { ...form.gender, disabled },
              name: { ...form.name, disabled },
              phone: { ...form.phone, disabled },
              email: { ...form.email, disabled },
              place: { ...form.place, disabled },
              birthday: { ...form.birthday, disabled },
              survey: { ...form.survey, disabled },
              marketing: { ...form.marketing, disabled },
              terms: { ...form.terms, disabled },
              form: {
                ...form.form,
                loading: false,
                alertSuccessfullySubmitted: false,
                alertAlreadySubmitted: false,
                alertError: true,
                alertErrorMessage:
                  "Настана непозната грешка. Пробајте повторно подоцна...",
                showForm: true,
              },
            });
          }
        })
        .catch((error) => {
          reCaptchaRef.current.reset();

          console.error("status", error.response.status);
          console.error("data", error.response.data);

          if (error.response.status === 400) {
            disabled = false;
            setForm({
              ...form,
              gender: { ...form.gender, disabled },
              name: { ...form.name, disabled },
              phone: { ...form.phone, disabled },
              email: { ...form.email, disabled },
              place: { ...form.place, disabled },
              birthday: { ...form.birthday, disabled },
              survey: { ...form.survey, disabled },
              marketing: { ...form.marketing, disabled },
              terms: { ...form.terms, disabled },
              form: {
                ...form.form,
                loading: false,
                alertSuccessfullySubmitted: false,
                alertAlreadySubmitted: false,
                alertError: true,
                alertErrorMessage: "Неуспешно! Пробајте повторно подоцна...",
                showForm: true,
              },
            });
          } else if (error.response.status === 403) {
            disabled = false;
            setForm({
              ...form,
              gender: { ...form.gender, disabled },
              name: { ...form.name, disabled },
              phone: { ...form.phone, disabled },
              email: { ...form.email, disabled },
              place: { ...form.place, disabled },
              birthday: { ...form.birthday, disabled },
              survey: { ...form.survey, disabled },
              marketing: { ...form.marketing, disabled },
              terms: { ...form.terms, disabled },
              form: {
                ...form.form,
                loading: false,
                alertSuccessfullySubmitted: false,
                alertAlreadySubmitted: false,
                alertError: true,
                alertErrorMessage:
                  "Неможеме да ве верификуваме! Пробајте повторно подоцна...",
                showForm: true,
              },
            });
          } else if (error.response.status === 406) {
            // The user has already claimed a vouhcer
            if (error.response.data.e.p.s) {
              setForm({
                ...form,
                gender: { ...form.gender, disabled },
                name: { ...form.name, disabled },
                phone: { ...form.phone, disabled },
                email: { ...form.email, disabled },
                place: { ...form.place, disabled },
                birthday: { ...form.birthday, disabled },
                survey: { ...form.survey, disabled },
                marketing: { ...form.marketing, disabled },
                terms: { ...form.terms, disabled },
                form: {
                  ...form.form,
                  loading: false,
                  alertError: false,
                  alertSuccessfullySubmitted: false,
                  alertAlreadySubmitted: true,
                  showForm: false,
                },
              });
              // Record local storage
              localStorage.setItem("vulcoClaimedCleaningSet2021", "y");
            } else {
              disabled = false;
              setForm({
                ...form,
                gender: { ...form.gender, disabled },
                name: { ...form.name, disabled },
                phone: { ...form.phone, disabled },
                email: { ...form.email, disabled },
                place: { ...form.place, disabled },
                birthday: { ...form.birthday, disabled },
                survey: { ...form.survey, disabled },
                marketing: { ...form.marketing, disabled },
                terms: { ...form.terms, disabled },
                form: {
                  ...form.form,
                  loading: false,
                  alertError: true,
                  alertErrorMessage: "Веќе постои ваучер за вас!",
                  alertSuccessfullySubmitted: false,
                  alertAlreadySubmitted: false,
                  showForm: true,
                },
              });
            }
          } else if (error.response.status === 428) {
            disabled = true;
            setForm({
              ...form,
              gender: { ...form.gender, disabled },
              name: { ...form.name, disabled },
              phone: { ...form.phone, disabled },
              email: { ...form.email, disabled },
              place: { ...form.place, disabled },
              birthday: { ...form.birthday, disabled },
              survey: { ...form.survey, disabled },
              marketing: { ...form.marketing, disabled },
              terms: { ...form.terms, disabled },
              form: {
                ...form.form,
                loading: false,
                alertError: true,
                alertErrorMessage: "Достапно само во Р. С. Македонија!",
                alertSuccessfullySubmitted: false,
                alertAlreadySubmitted: false,
                showForm: false,
              },
            });
          } else {
            disabled = false;
            setForm({
              ...form,
              gender: { ...form.gender, disabled },
              name: { ...form.name, disabled },
              phone: { ...form.phone, disabled },
              email: { ...form.email, disabled },
              place: { ...form.place, disabled },
              birthday: { ...form.birthday, disabled },
              survey: { ...form.survey, disabled },
              marketing: { ...form.marketing, disabled },
              terms: { ...form.terms, disabled },
              form: {
                ...form.form,
                loading: false,
                alertError: true,
                alertErrorMessage:
                  "Настана непозната грешка! Пробајте повторно подоцна...",
                alertSuccessfullySubmitted: false,
                alertAlreadySubmitted: false,
                showForm: true,
              },
            });
          }
        });
    } else {
      disabled = false;
      setForm({
        ...form,
        gender: { ...form.gender, disabled, error: errorGender },
        name: { ...form.name, disabled, error1: errorName, error2: errorName },
        phone: { ...form.phone, disabled, error: errorPhone },
        email: { ...form.email, disabled, error: errorEmail },
        place: { ...form.place, disabled, error: errorPlace },
        birthday: { ...form.birthday, disabled, error: errorBirthday },
        survey: { ...form.survey, disabled, error: errorSurvey },
        marketing: { ...form.marketing, disabled },
        terms: { ...form.terms, disabled, error: errorTerms },

        form: {
          ...form.form,
          loading: false,
          alertError: true,
          alertErrorMessage: "Точно пополнете ги сите полиња!",
          alertSuccessfullySubmitted: false,
          alertAlreadySubmitted: false,
          showForm: true,
        },
      });
    }
  };

  const showForm = () => {
    if (form.form.showForm) {
      return (
        <form onSubmit={(e) => e.preventDefault()}>
          <Box mx={2} pt={2.5} pb={3}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={{ xs: 2, md: 8 }}
            >
              {
                // Section 1 - Start
              }
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{ minWidth: { xs: "auto", md: 390 } }}
              >
                <FormControl sx={{ minWidth: { xs: 230, sm: 300, md: 130 } }}>
                  <InputLabel id="gender-label" error={form.gender.error}>
                    Обраќање
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="gender-label"
                    id="gender"
                    disabled={form.gender.disabled}
                    value={form.gender.value}
                    error={form.gender.error}
                    onChange={handleFormGender}
                    label="Обраќање"
                  >
                    <MenuItem value={""} disabled>
                      <em>Како да ти се обраќаме...</em>
                    </MenuItem>
                    <MenuItem value={"f"}>г-ца/г-ѓа (Почитувана)</MenuItem>
                    <MenuItem value={"m"}>г-дин (Почитуван)</MenuItem>
                    <MenuItem value={"n"}>неутрално (Почитувани)</MenuItem>
                  </Select>
                  <FormHelperText>{form.gender.message}</FormHelperText>
                </FormControl>
                <FormControl>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={{ xs: 2, md: 1 }}
                  >
                    <FormControl
                      sx={{ minWidth: { xs: 300, sm: 400, md: 120 } }}
                    >
                      <TextField
                        id="name_first"
                        label="Име"
                        variant="outlined"
                        disabled={form.name.disabled}
                        error={form.name.error1}
                        helperText={form.name.message1}
                        onChange={handleFormName}
                      />
                    </FormControl>
                    <FormControl
                      sx={{ minWidth: { xs: 300, sm: 400, md: 120 } }}
                    >
                      <TextField
                        id="name_last"
                        label="Презиме"
                        variant="outlined"
                        disabled={form.name.disabled}
                        error={form.name.error2}
                        helperText={form.name.message2}
                        onChange={handleFormName}
                      />
                    </FormControl>
                  </Stack>
                </FormControl>

                <FormControl>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={{ xs: 2, md: 1 }}
                  >
                    <FormControl
                      sx={{ minWidth: { xs: 230, sm: 300, md: 120 } }}
                    >
                      <InputLabel id="prefix-label">Префикс</InputLabel>
                      <Select
                        autoWidth
                        labelId="prefix-label"
                        id="prefix"
                        disabled={true}
                        value={"MK"}
                        defaultValue={"MK"}
                        label="Префикс"
                        error={form.phone.error}
                      >
                        <MenuItem value={"MK"} selected>
                          +389
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{ minWidth: { xs: 300, sm: 400, md: 120 } }}
                    >
                      <TextField
                        id="phone"
                        type="number"
                        label="Мобилен"
                        variant="outlined"
                        disabled={form.phone.disabled}
                        error={form.phone.error}
                        helperText={form.phone.message}
                        onChange={handleFormPhone}
                      />
                    </FormControl>
                  </Stack>
                </FormControl>
                <FormControl sx={{ minWidth: { xs: 300, sm: 400, md: 370 } }}>
                  <TextField
                    id="email"
                    type="email"
                    label="E-mail"
                    variant="outlined"
                    disabled={form.email.disabled}
                    error={form.email.error}
                    helperText={form.email.message}
                    onChange={handleFormEmail}
                  />
                </FormControl>

                <FormControl>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <FormControl
                      sx={{ minWidth: { xs: 230, sm: 300, md: 155 } }}
                    >
                      <InputLabel id="place-label" error={form.place.error}>
                        Живеалиште
                      </InputLabel>
                      <Select
                        autoWidth
                        labelId="place-label"
                        id="place"
                        value={form.place.value}
                        error={form.place.error}
                        disabled={form.place.disabled}
                        onChange={handleFormPlace}
                        label="Живеалиште"
                      >
                        <MenuItem value={""} disabled>
                          <em>Избери место на живеење...</em>
                        </MenuItem>

                        {country.MK.place.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{form.place.message1}</FormHelperText>
                    </FormControl>

                    {form.place.value === "other" && (
                      <FormControl>
                        <TextField
                          id="place_other"
                          type="text"
                          label="Населно место"
                          variant="outlined"
                          error={form.place.error}
                          disabled={form.place.disabled}
                          helperText={form.place.message2}
                          onChange={handleFormPlace}
                        />
                      </FormControl>
                    )}
                  </Stack>
                </FormControl>

                <FormControl sx={{ minWidth: { xs: 230, sm: 300, md: 120 } }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={mkLocale}
                  >
                    <MobileDatePicker
                      disableElevation
                      mode="landscape"
                      mask={"__.__.____ __:__"}
                      disableFuture
                      error={false}
                      hintText="Portrait Dialog"
                      errorText="This is an error message."
                      label="Роденден"
                      floatingLabelText="Роденден"
                      inputFormat="dd/MM/yyyy"
                      value={form.birthday.date}
                      disabled={form.birthday.disabled}
                      okText="Избери"
                      openTo="year"
                      todayText="Денес"
                      toolbarTitle="Избери датум"
                      cancelText="Откажи"
                      views={["year", "month", "day"]}
                      onChange={handleFormBirthday}
                      renderInput={(params) => (
                        <TextField error={form.birthday.error} {...params} />
                      )}
                      allowSameDateSelection={false}
                      disableHighlightToday={true}
                      showTodayButton={false}
                      showToolbar={false}
                      maxDate={dateMax}
                    />
                    <FormHelperText error={form.birthday.error}>
                      {form.birthday.message}
                    </FormHelperText>
                  </LocalizationProvider>
                </FormControl>
              </Stack>
              {
                // Section 1 - End
              }

              {
                // Section 2 - Start
              }
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <FormControl sx={{ minWidth: { xs: 230, sm: 300, md: 200 } }}>
                  <InputLabel id="survey-label" error={form.survey.error}>
                    Како дозна за нас?
                  </InputLabel>
                  <Select
                    label="Како дозна за нас?"
                    labelId="survey-label"
                    id="survey"
                    multiple
                    value={form.survey.list}
                    onChange={handleFormSurvey}
                    error={form.survey.error}
                    disabled={form.survey.disabled}
                    renderValue={(selected) => {
                      var len = selected.length;
                      var msg = "";
                      if (len === 1) {
                        msg = "1 опција избрана";
                      } else if (len > 1) {
                        msg = len + " опции избрани";
                      }
                      return msg;
                    }}
                  >
                    <MenuItem value={""} disabled>
                      <em>Избери една или повеќе од опциите...</em>
                    </MenuItem>
                    {surveyOptions.map((option) => (
                      <MenuItem
                        key={option.value + option.name}
                        value={option.value}
                      >
                        <Checkbox
                          checked={form.survey.list.indexOf(option.value) > -1}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{form.survey.message}</FormHelperText>
                </FormControl>

                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Typography color="primary" variant="button" fontWeight="500">
                    Прифати ги условите...
                  </Typography>

                  <FormControl>
                    <FormLabel focused={false} error={form.terms.error}>
                      Сакам да добивам{" "}
                      <Typography fontWeight="500" component="span">
                        роденденски
                      </Typography>{" "}
                      специјални{" "}
                      <Typography fontWeight="500" component="span">
                        попсути
                      </Typography>
                      /
                      <Typography fontWeight="500" component="span">
                        промоции
                      </Typography>{" "}
                      за{" "}
                      <Typography fontWeight="500" component="span">
                        мојот роденден
                      </Typography>{" "}
                      и други промотивни известувања (директен маркетинг).
                      Невклучувајќи трансакциски известувања.
                    </FormLabel>

                    <FormControlLabel
                      disabled={form.marketing.disabled}
                      control={
                        <Checkbox
                          checked={form.marketing.checked}
                          onChange={handleFormMarketing}
                          name="confirm_marketing"
                          disabled={form.marketing.disabled}
                        />
                      }
                      label={
                        <Typography
                          variant="button"
                          color={form.terms.error ? "error" : ""}
                        >
                          Потврдувам
                        </Typography>
                      }
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel
                      focused={false}
                      disabled={form.terms.disabled}
                      error={form.terms.error}
                    >
                      Ги прифаќам долунаведените правила и Политиката на
                      Приватност
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={form.terms.checked}
                          onChange={handleFormTerms}
                          name="confirm_id"
                          disabled={form.terms.disabled}
                        />
                      }
                      label={
                        <Typography
                          variant="button"
                          color={form.terms.error ? "error" : ""}
                        >
                          Потврдувам
                        </Typography>
                      }
                    />
                  </FormControl>
                </Stack>

                <FormControl>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    loading={form.form.loading}
                    disableElevation
                    onClick={handleFormSubmit}
                  >
                    Испрати
                  </LoadingButton>
                </FormControl>
              </Stack>
              {
                // Section 2 - End
              }
            </Stack>
          </Box>
        </form>
      );
    }
  };

  return (
    <React.Fragment>
      <Box
        minHeight={{
          xs: "30rem",
          sm: "28rem",
          md: "33rem",
        }}
        mb={3}
        sx={{
          backgroundColor: "rgb(0,73,169)",
          background:
            "radial-gradient(circle, rgba(0,73,169,1) 51%, rgba(0,29,169,1) 100%)",
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 0, sm: -2, md: 0 }}
          pt={{ xs: 11, sm: 15, md: 12 }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: 210, sm: 300, md: 430 },
              width: { xs: 250, sm: 340, md: 490 },
            }}
            src={gyCleaningSet}
            alt="Goodyear Winter Range 2021"
            pl={{ xs: 0, sm: 6, md: 0 }}
            mt={{ sm: 5, md: 0 }}
          />
          <Box>
            <Typography
              //variant="h2"
              sx={{
                fontWeight: "bold",
                lineHeight: "1",
                fontSize: {
                  xs: "2.125rem",
                  sm: "3rem",
                  md: "3.75rem",
                },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
              pr={{ xs: 0, sm: 5, md: 6 }}
              color="primary.contrastText"
              gutterBottom
            >
              <em>
                Земи си и
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                  }}
                >
                  <br />
                  подарок
                </Box>
              </em>
            </Typography>
            <Typography
              variant="h5"
              color="primary.contrastText"
              align="left"
              mt={{ xs: 2, sm: 0 }}
              mr={{ sm: 5, md: 0 }}
              sx={{
                fontWeight: "bold",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
              gutterBottom
            >
              <em>Goodyear сет за чистење</em>
            </Typography>
            <Typography
              variant="h6"
              color="primary.contrastText"
              align="left"
              mt={{ xs: 2, sm: 2 }}
              mb={{ xs: 4, md: 0 }}
              mr={{ sm: 5, md: 0 }}
              sx={{
                fontWeight: "bold",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
                fontSize: {
                  xs: "1rem",
                  md: "1.25rem",
                },
              }}
              gutterBottom
            >
              <Box
                component="span"
                sx={{
                  color: "secondary.main",
                }}
              >
                <em>Чекори:</em>
              </Box>
              <br />
              <em>
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                  }}
                >
                  1.
                </Box>{" "}
                Пополни го формуларот...
              </em>
              <br />
              <em>
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                  }}
                >
                  2.
                </Box>{" "}
                Добиваш ваучер на SMS/E-mail
              </em>
              <br />
              <em>
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                  }}
                >
                  3.
                </Box>{" "}
                Си го земаш подарокот со 4 гуми
              </em>
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Container maxWidth={"md"}>
        {alertSuccessfullySubmitted()}
        {alertAlreadySubmitted()}
        {alertError()}

        {
          // ReCAPTCHA
        }
        <Box sx={{ position: "absolute", visibility: "hidden", opacity: 0 }}>
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey={global.config.recaptcha.key}
            data-callback="callback"
            //badge="inline"
          />
        </Box>

        <Paper elevation={0}>
          {showForm()}
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={
                <FontAwesomeIcon
                  className={styles.accordionSummaryIcon}
                  icon={faChevronDown}
                  fixedWidth
                />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                className={styles.accordionSummaryTitle}
                variant={"button"}
              >
                Правила
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={"body2"} paragraph>
                1. Оваа промоција трае до 30.05.2022 или до крајот на залихите
                само во Vulco Прилеп.
              </Typography>
              <Typography variant={"body2"} paragraph>
                2. Промотивниот подарокот може да го подигнете само доколу:
                <br />
                2.1. Гумите ги купите како физичко лице и монтирате во Vulco
                Прилеп.
                <br />
                2.2. Го пополните формулатор погоре со точни податоци и го
                добиете ваучерот по пат на SMS или E-mail.
                <br />
                2.3. Истовремено купите четири M+S или целосезонски (патнички,
                4x4/Off-Road, SUV) гуми од брендовите Goodyear, Dunlop, Fulda,
                Sava, Dębica, Diplomat и Pirelli.
                <br />
                2.4. И доколку податоците кои ги имате внесено во формуларот
                погоре се софпаѓаат со тие во вашиот личен документ (со
                фотографија) при легитимација.
              </Typography>
              <Typography variant={"body2"} paragraph>
                3. Промотивниот подарок не може да се замени за готовина или
                друг производ.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

/***8
 * 
 * 
 * 
 * 
 * 
 *  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Роденден"
                      inputFormat="dd/MM/yyyy"
                      //value={value}
                      //onChange={handleChange}
                      //renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
 */
