import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  Card,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCalendarCheck,
  faMapMarkerAlt,
} from "@fortawesome/pro-solid-svg-icons";

import Sidebar from "./Sidebar";

import VulcoLogo from "./vulco.svg";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    background: theme.palette.background.default + "!important",
  },
  location: {
    //float: "right",
    color: theme.palette.primary.contrastText + "!important",

    "&::disabled": {
      color: theme.palette.primary.contrastText + "!important",
    },
  },
  locationDisabled: {},
}));

export default function Header() {
  const navigate = useNavigate();

  const [drawer, setDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const styles = useStyles();

  return (
    <React.Fragment>
      <AppBar
        // Header Logo Shadow
        sx={{
          position: "fixed",
          top: 0,
          mt: { xs: "8px", sm: "12.5px" },
          pointerEvents: "none",
        }}
      >
        <Box
          // Header Logo
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            minWidth: "100%",
            maxWidth: "100%",
            pointerEvents: "none",
          }}
        >
          <Box
            component="div"
            sx={{
              position: "fixed",
              height: { xs: 72, sm: 77 },
              width: { xs: 225, sm: 240 }, //sm 40 - 41
              pointerEvents: "none",
              borderRadius: "50%",
              boxShadow: ({ palette }) => "0 0 100px " + palette.secondary.main,
            }}
          />
        </Box>
      </AppBar>
      <AppBar sx={{ position: "sticky" }} elevation={0}>
        <Toolbar sx={{ display: "flex" }}>
          <Box sx={{ display: "flex" }} flexGrow={1}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
              onKeyDown={toggleDrawer(true)}
            >
              <FontAwesomeIcon icon={faBars} fixedWidth />
            </IconButton>
          </Box>

          <Button
            size="large"
            sx={{ display: "none", mr: 2 }}
            onClick={() => navigate("/contact")}
            color="secondary"
            variant="contained"
            disableElevation
            startIcon={<FontAwesomeIcon icon={faCalendarCheck} />}
          >
            Закажи сервис
          </Button>

          <Button
            size="large"
            sx={{ display: { xs: "none", sm: "flex" } }}
            onClick={() => navigate("/contact")}
            //color="info"
            variant="contained"
            disableElevation
            classes={
              {
                //root: styles.location,
                //disabled: styles.locationDisabled,
              }
            }
            startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
          >
            Прилеп
          </Button>
        </Toolbar>

        <Box
          // Header Logo
          component="div"
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            top: 0,
            mt: { xs: "8px", sm: "12.5px" },
            minWidth: "100%",
            maxWidth: "100%",
            pointerEvents: "none",
            //background: "red",
          }}
        >
          <Card
            sx={{ borderRadius: "50%" }}
            elevation={0}
            onClick={() => navigate("/")}
          >
            <CardActionArea>
              <CardMedia
                component="div"
                image={VulcoLogo}
                sx={{
                  height: { xs: 72, sm: 77 },
                  width: { xs: 225, sm: 240 }, //sm 40 - 41
                  pointerEvents: "auto",
                  cursor: "pointer",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  //background: "red",
                }}
              />
            </CardActionArea>
          </Card>
        </Box>
      </AppBar>

      <Sidebar drawer={drawer} toggleDrawer={toggleDrawer} />
    </React.Fragment>
  );
}

//, md: 75, lg: 75, xl: 75

/*
<Box
            component="img"
            src={VulcoLogo}
            sx={{
              height: { xs: 70, sm: 76 },
              shadows: 2,
              dropShadow: 20,
              pointerEvents: "auto",
              cursor: "pointer",
              background: "red",
              borderRadius: "50%",
            }}
            onClick={() => navigate("/")}
          />




                    <Button
            size="large"
            sx={{ display: { xs: "none", sm: "flex" } }}
            onClick={() => navigate("/contact")}
            classes={{
              root: styles.location,
              disabled: styles.locationDisabled,
            }}
            startIcon={<FontAwesomeIcon icon={faCalendarCheck} fixedWidth />}
          >
            Закажи сервис
          </Button>
*/
