import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  useMediaQuery,
} from "@mui/material";

import Home from "./Home/Home";
import Header from "./Header";
import Footer from "./Footer/Footer";

import { LegalPrompt, LegalDialogPrivacyPolicy } from "./Legal";

import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";

import gyBlanketWinter2021 from "./Promotion/Goodyear/BlanketWinter2021";
import duBlanketWinter2021 from "./Promotion/Dunlop/BlanketWinter2021";
import fuCablesWinter2021 from "./Promotion/Fulda/CablesWinter2021";
import CleaningSet2021 from "./Promotion/Gift/CleaningSet2021/CleaningSet2021";

import LiquiMolyGiftAdditive from "./Promotion/LiquiMoly/LiquiMolyGiftAdditive/LiquiMolyGiftAdditive";

import GYEF1A6withGift from "./Promotion/Goodyear/GYEF1A6/GYEF1A6withGift";

import Booking from "./Booking/Booking";

//import "@fontsource/roboto/300.css";
//import "@fontsource/roboto/400.css";
//import "@fontsource/roboto/500.css";
//import "@fontsource/roboto/700.css";
//import "@fontsource/roboto/cyrillic-ext-300.css";
//import "@fontsource/roboto/cyrillic-ext-400.css";
//import "@fontsource/roboto/cyrillic-ext-500.css";
//import "@fontsource/roboto/cyrillic-ext-700.css";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
//import "@fontsource/rubik/800.css";
import "@fontsource/rubik/900.css";
//import "@fontsource/rubik/cyrillic-ext-700.css";
//import "@fontsource/rubik/cyrillic-ext-800.css";
//import "@fontsource/rubik/cyrillic-ext-900.css";

const typography = {
  fontFamily: ["Rubik", "Helvetica", "Arial", "sans-serif"].join(","),
  h1: {
    fontWeight: 900,
  },
};

const primary = {
  //main: "#00309d",
  main: "rgb(0, 48, 157)",
  dark: "rgb(0, 33, 109)",
  light: "rgb(51, 89, 176)",
  //light: "rgb(0, 118, 179)",
};

const secondary = {
  //main: "#ffcd00",
  main: "rgb(255, 205, 0)",
  light: "rgb(255, 215, 51)",
  dark: "rgb(178, 143, 0)",
};

const text = {
  light: {
    primary: "rgba(0,0,0,0.87)",
    secondary: "rgba(0,0,0,0.6)",
    disabled: "rgba(0,0,0,0.38)",
  },
  dark: {
    primary: "rgb(255,255,255)",
    secondary: "rgba(255,255,255,0.6)",
    disabled: "rgba(255,255,255,0.38)",
  },
};

const themeLight = createTheme({
  typography: typography,
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: { ...primary, gradient: primary.light },
    secondary: { ...secondary, gradient: secondary.light },
    background: {
      default: "rgb(245, 245, 245)",
      paper: "#ffffff",
    },
    text: { ...text.light, contrast: { ...text.dark }, fixed: { ...text } },
    divider: "rgba(0,0,0,0.12)",
    card: {
      background: {
        default: "#ffffff",
        hover: "rgba(200,200,200,0.1)",
      },
    },
    box: {
      stretched: {
        background: "transparent",
      },
    },
    accordion: {
      title: text.light.primary,
      icon: text.light.secondary,
    },
    list: {
      icon: text.light.secondary,
    },
    page: {
      name: {
        background: primary.light,
      },
    },
  },
});

const themeDark = createTheme({
  typography: typography,
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: { ...primary, gradient: primary.dark },
    secondary: { ...secondary, gradient: secondary.dark },
    background: {
      default: "#0d0d0d",
      //paper: "rgba(255, 255, 255, 0.1)",
      paper: "#262626",
    },
    text: { ...text.dark, contrast: { ...text.light }, fixed: { ...text } },
    divider: "rgba(255,255,255,0.12)",
    card: {
      background: {
        default: "#0d0d0d",
        hover: "rgba(0,0,0,0.02)",
      },
    },
    box: {
      stretched: {
        background: "transparent",
      },
    },
    accordion: {
      title: text.dark.primary,
      icon: text.dark.secondary,
    },
    list: {
      icon: text.dark.secondary,
    },
    page: {
      name: {
        background: primary.dark,
      },
    },
  },
});

/*
withRouter depricated 
function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);
*/

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

export default function Portal() {
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");
  const [state, setState] = useState({
    policyAccepted: false,
    policyDialogShow: false,
    policyDialogButtonClose: false,
  });

  useEffect(() => {
    if (
      !localStorage.getItem("vulcoPrivacyPolicyAccepted") &&
      !state.policyAccepted
    ) {
      setState({ ...state, policyAccepted: true });
    }
  }, [state]);

  const handlePolicyAcceptButton = (e) => {
    localStorage.setItem(
      "vulcoPrivacyPolicyAccepted",
      new Date().toISOString()
    );
    setState({
      ...state,
      policyAccepted: false,
      policyDialogShow: false,
    });
  };

  const handlePolicyDialogCloseButton = (e) => {
    setState({
      ...state,
      policyDialogShow: false,
    });
  };

  const handlePolicyDialog = (e) => {
    var dialogButtonClose = false;
    var dialogShow = true;
    var acceptShow = false;

    if (e.target.name === "close_dialog") {
      dialogButtonClose = true;
      dialogShow = true;
    } else if (e.target.name === "accept_dialog") {
      dialogButtonClose = false;
    } else {
      dialogButtonClose = true;
    }
    setState({
      ...state,
      policyAccepted: acceptShow,
      policyDialogButtonClose: dialogButtonClose,
      policyDialogShow: dialogShow,
    });
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop>
          <ThemeProvider theme={isDarkModeEnabled ? themeDark : themeLight}>
            <CssBaseline />
            <Header />

            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<ContactUs />} />

              <Route
                path="/promo/goodyear/winter"
                element={<gyBlanketWinter2021 />}
              />
              <Route
                path="/promo/dunlop/winter"
                element={<duBlanketWinter2021 />}
              />
              <Route
                path="/promo/fulda/winter"
                element={<fuCablesWinter2021 />}
              />
              <Route path="/gy/ef1a6" element={<GYEF1A6withGift />} />
              <Route path="/podarok" element={<CleaningSet2021 />} />
              <Route path="/rezerviraj" element={<Booking />} />
              <Route path="/maslo/motor/lm" element={<LiquiMolyGiftAdditive />} />
            </Routes>

            <Footer handlePolicyDialog={handlePolicyDialog} />

            <LegalPrompt
              policyAccepted={state.policyAccepted}
              handlePolicyDialog={handlePolicyDialog}
              handlePolicyAcceptButton={handlePolicyAcceptButton}
            />

            <LegalDialogPrivacyPolicy
              policyDialogShow={state.policyDialogShow}
              policyDialogButtonClose={state.policyDialogButtonClose}
              handlePolicyAcceptButton={handlePolicyAcceptButton}
              handlePolicyDialogCloseButton={handlePolicyDialogCloseButton}
            />
          </ThemeProvider>
        </ScrollToTop>
      </BrowserRouter>
    </React.Fragment>
  );
}
//         <Navigate to="/" />
