import React from "react";
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Chip,
  Stack,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from "@fortawesome/pro-solid-svg-icons";

import LiquiMoly_4200TopTec5L_5W_30 from "./Oils/LiquiMoly_4200TopTec5L_5W_30.webp";
import LiquiMoly_4100TtopTec5L_5W_40 from "./Oils/LiquiMoly_4100TtopTec5L_5W_40.webp";
import LiquiMoly_4600TopTec5L_5W_30 from "./Oils/LiquiMoly_4600TopTec5L_5W_30.webp";
import LiquiMoly_Molygen5L_5W_40 from "./Oils/LiquiMoly_Molygen5L_5W_40.webp";
import OilCover from "./Oils/oil_cover.webp";
import LiquiMoly_EngineFlush from "./Oils/liqui_moly_engine_flush.webp";

export default function LiquiMolyGiftAdditive() {
  return (
    <React.Fragment>
      <Box
        // Header
        sx={{
          height: {
            xs: "30rem",
            sm: "29rem",
            md: "32rem",
            lg: "38rem",
          },

          backgroundColor: "black",
        }}
      >
        <CardMedia
          component="div"
          sx={{
            float: "left",
            width: "100%",
            height: "100%",

            backgroundSize: "auto 145%",
            backgroundPosition: "right bottom",
          }}
          image={OilCover}
          alt="Oil"
        />

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          //spacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{
            position: "absolute",
            top: { xs: "14rem", sm: "14rem", md: "16rem", lg: "20rem" },
            width: "100%",
          }}
        >
          <List dense={true} sx={{ ml: { xs: "5%", sm: "3%" } }}>
            <ListItem>
              <ListItemIcon
                sx={{ color: ({ palette }) => palette.primary.main }}
              >
                <FontAwesomeIcon icon={faCheck} fixedWidth size="4x" beat />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      textTransform: "uppercase",
                      pt: 6,
                      color: ({ palette }) => palette.primary.main,
                      fontWeight: 900,
                    }}
                  >
                    Гратис Адитив
                  </Typography>
                }
                secondary={
                  <Typography component="div" variant="h6" color="text.primary">
                    Со 5L моторно масло на Liqui Moly
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon
                sx={{ color: ({ palette }) => palette.primary.main }}
              >
                <FontAwesomeIcon icon={faCheck} fixedWidth size="4x" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      textTransform: "uppercase",
                      pt: 2,
                      color: ({ palette }) => palette.primary.main,
                      fontWeight: 900,
                    }}
                  >
                    Бесплатна промена
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Stack>
      </Box>

      <Container maxWidth={"lg"} sx={{ mt: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Card elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 150, mx: 3, my: 2 }}
                  image={LiquiMoly_Molygen5L_5W_40}
                  alt="LiquiMoly_Molygen5L_5W_40"
                />
                <CardContent>
                  <Typography component="div" variant="h5">
                    Liqui Moly
                    <br />
                    Molygen 5L
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    variant="h5"
                  >
                    5W-40
                  </Typography>
                  <Chip
                    color="secondary"
                    label={
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        2,250 MKD
                      </Typography>
                    }
                    sx={{ mt: 3 }}
                  />
                </CardContent>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 150, mx: 3, my: 2 }}
                  image={LiquiMoly_4200TopTec5L_5W_30}
                  alt="LiquiMoly_4200TopTec5L_5W_30"
                />
                <CardContent>
                  <Typography component="div" variant="h5">
                    Liqui Moly
                    <br />
                    4200 Top Tec 5L
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    variant="h5"
                  >
                    5W-30
                  </Typography>
                  <Chip
                    color="secondary"
                    label={
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        4,400 MKD
                      </Typography>
                    }
                    sx={{ mt: 3 }}
                  />
                </CardContent>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 150, mx: 3, my: 2 }}
                  image={LiquiMoly_4100TtopTec5L_5W_40}
                  alt="LiquiMoly_4100TtopTec5L_5W_40"
                />
                <CardContent>
                  <Typography component="div" variant="h5">
                    Liqui Moly
                    <br />
                    4100 Top Tec 5L
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    variant="h5"
                  >
                    5W-40
                  </Typography>
                  <Chip
                    color="secondary"
                    label={
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        2,950 MKD
                      </Typography>
                    }
                    sx={{ mt: 3 }}
                  />
                </CardContent>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Card elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 150, mx: 3, my: 2 }}
                  image={LiquiMoly_4600TopTec5L_5W_30}
                  alt="LiquiMoly_4600TopTec5L_5W_30"
                />
                <CardContent>
                  <Typography component="div" variant="h5">
                    Liqui Moly
                    <br />
                    4600 Top Tec 5L
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    variant="h5"
                  >
                    5W-30
                  </Typography>
                  <Chip
                    color="secondary"
                    label={
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        3,250 MKD
                      </Typography>
                    }
                    sx={{ mt: 3 }}
                  />
                </CardContent>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

/**
 * 
 * 
 *  <Box
          sx={{
            // Bottle
            position: "absolute",
            float: "right",
            width: "30%",
            mt: { md: 2, lg: 5 },
            ml: { md: "30%", lg: "20%" },
            height: {
              xs: "30rem",
              sm: "29rem",
              md: "32rem",
              lg: "38rem",
            },
          }}
        >
          <CardMedia
            // Logo
            component="div"
            sx={{
              width: 200,
              height: "100%",

              //backgroundColor: "red",
              "-webkit-transform": "rotate(90deg)",
              "-moz-transform": "rotate(90deg)",
              "-ms-transform": "rotate(90deg)",
              "-o-transform": "rotate(90deg)",
              transform: "rotate(60deg)",

              backgroundSize: "contain",
              backgroundPosition: "right center",
            }}
            image={LiquiMoly_EngineFlush}
            alt="Oil"
          />
        </Box>
 */
