import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faSnowflake,
  faSunDust,
  faTrophy,
} from "@fortawesome/pro-solid-svg-icons";

import fuLogo from "./fu_h_logo.min.png";
import fuCover from "./fuCoverWinter2021.min.jpg";
import fuKristallControlHP2 from "./fu_kri_cont_hp2.min.jpg";
import fuKristallMontero3 from "./fu_kri_mont_3.min.jpg";
import fuMultiControl from "./fu_mu_cont.min.jpg";
import vulcoCables2021 from "./vulco_cables_2021.min.png";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${fuCover})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  titleHighlight: {
    fontWeight: theme.typography.fontWeightBold + "!important",
    color: "rgba(0,0,0,0.87)",
  },
  boxHighlight: {
    width: "100%",
    //background: theme.palette.primary.main,
    //background: theme.palette.divider,
    //background: "#ffdd00",
    background: theme.palette.primary.contrastText,
    pointerEvents: "none",
    textAlign: "center",
  },
  accordionSummaryTitle: {
    color: "theme.palette.accordion.title",
  },
  accordionSummaryIcon: {
    color: theme.palette.text.icon,
  },
}));

export default function BlanketWinter2021() {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        className={styles.image}
        height={{
          xs: "40vh",
          sm: "48vh",
          md: "55vh",
          xl: "52vh",
        }}
        marginTop={{
          xs: 7,
          sm: 7,
        }}
      />
      <Box
        className={styles.boxHighlight}
        paddingTop={{ xs: 4.5 }}
        paddingBottom={{ xs: 2.5 }}
        marginBottom={{ xs: 3 }}
      >
        <Container maxWidth={"xl"}>
          <img src={fuLogo} alt="Fulda Logo" height={70} />
          <Typography
            className={styles.titleHighlight}
            variant={"h6"}
            component={"h1"}
            my={2}
            mt={1}
            px={4}
          >
            Уживај во сигурна контрола и оваа зима!
          </Typography>
        </Container>
      </Box>

      <Container maxWidth={"xl"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          mb={3}
        >
          <Grid item xs={6} md={4} lg={4}>
            <Card className={styles.card} elevation={0}>
              <CardMedia
                component="img"
                height={{ xs: 500, md: 400, lg: 350 }}
                image={fuMultiControl}
                alt="Fulda MultiControl"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Fulda MultiControl
                </Typography>
                <Stack
                  spacing={1}
                  justifyContent="flex-start"
                  direction={{
                    xs: "column",
                    xl: "row",
                  }}
                >
                  <Chip
                    icon={<FontAwesomeIcon icon={faTrophy} fixedWidth />}
                    label="ВИСОКО ПРЕПОРАЧАНА"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Chip
                    icon={<FontAwesomeIcon icon={faSunDust} fixedWidth />}
                    variant="outlined"
                    label="ЦЕЛОСЕЗОНСКА"
                    sx={{ fontWeight: "bold" }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <Card className={styles.card} elevation={0}>
              <CardMedia
                component="img"
                height={{ xs: 500, md: 400, lg: 350 }}
                image={fuKristallControlHP2}
                alt="Fulda Kristall Control HP2"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Fulda Kristall Control HP2
                </Typography>
                <Stack
                  spacing={1}
                  justifyContent="flex-start"
                  direction={{
                    xs: "column",
                    xl: "row",
                  }}
                >
                  <Chip
                    icon={<FontAwesomeIcon icon={faTrophy} fixedWidth />}
                    label="ВИСОКО ПРЕПОРАЧАНА"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Chip
                    icon={<FontAwesomeIcon icon={faSnowflake} fixedWidth />}
                    variant="outlined"
                    label="M+S"
                    sx={{ fontWeight: "bold" }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <Card className={styles.card} elevation={0}>
              <CardMedia
                component="img"
                height={{ xs: 500, md: 400, lg: 350 }}
                image={fuKristallMontero3}
                alt="Fulda Kristall Montero 3"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Fulda Kristall Montero 3
                </Typography>
                <Stack
                  spacing={1}
                  justifyContent="flex-start"
                  direction={{
                    xs: "column",
                    xl: "row",
                  }}
                >
                  <Chip
                    icon={<FontAwesomeIcon icon={faTrophy} fixedWidth />}
                    label="ВИСОКО ПРЕПОРАЧАНА"
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                  />
                  <Chip
                    icon={<FontAwesomeIcon icon={faSnowflake} fixedWidth />}
                    variant="outlined"
                    label="M+S"
                    sx={{ fontWeight: "bold" }}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={"md"}>
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom paragraph px={2} pt={3}>
            ...
          </Typography>

          <Typography variant="body1" paragraph px={2} pb={3}>
            За да ја направиме одлуката уште полесна оваа зима,{" "}
            <Box component="span" fontWeight="fontWeightMedium">
              со истовремено купување 4 гуми Fulda M+S или целосезонски 16" или
              поголеми во Vulco Прилеп до 18.12.2021, ќе ви подариме Goodyear
              кабли со LED светилка
            </Box>
            .
          </Typography>
        </Paper>
      </Container>

      <Box
        py={{ xs: 5 }}
        marginBottom={{ xs: 3 }}
        mt={3}
        bgcolor="primary.main"
      >
        <Container maxWidth={"xl"}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 4, md: 5 }}
            pr={{ xs: 0, md: 3 }}
          >
            <CardMedia
              sx={{
                height: { xs: 250, md: 280 },
                width: { xs: 300, md: 320 },
              }}
              image={vulcoCables2021}
            />
            <Box>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems={{ xs: "center", md: "flex-start" }}
                spacing={{ xs: 2, md: 1 }}
              >
                <Typography
                  variant={"h4"}
                  component={"h3"}
                  color="secondary"
                  className={styles.titleHighlight}
                >
                  ПОДАРОК
                </Typography>
                <Typography
                  className={styles.titleHighlight}
                  variant={"h5"}
                  component={"h4"}
                  textAlign="left"
                  color="#ffffff"
                >
                  Goodyear кабли со LED
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="md">
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={
              <FontAwesomeIcon
                className={styles.accordionSummaryIcon}
                icon={faChevronDown}
                fixedWidth
              />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              className={styles.accordionSummaryTitle}
              variant={"button"}
            >
              Правила
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant={"body2"} paragraph>
              1. Промоцијата трае до 18.12.2021 или до крајот на залихите. Само
              во Vulco Прилеп.
            </Typography>
            <Typography variant={"body2"} paragraph>
              2. Промоцијата важи за истовремено купување на четири Dunlop M+S
              или целосезонски гуми од 16" или поголеми.
            </Typography>
            <Typography variant={"body2"} paragraph>
              3. Промотивната кампања важи за физички и правни лица.
            </Typography>
            <Typography variant={"body2"} paragraph>
              4. Едно правно или физичко лице може да добие најмногу два
              подароци дококу истовремено купи осум или повеќе гуми.
            </Typography>
            <Typography variant={"body2"} paragraph>
              5. Промотивен подарок е јорган од Шведскиот бренд Vingа.
            </Typography>
            <Typography variant={"body2"} paragraph>
              6. Промотивниот подарок не може да се замени за готовина или друг
              производ.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>

      <Container maxWidth={"md"}></Container>
    </React.Fragment>
  );
}
